export const APP_NAME = 'Hi India Events';
// export const GOOGLE_CLIENT_ID = '847880446094-tdiggml9iie7qi8rgeelgndha0tej3p8.apps.googleusercontent.com';
export const GOOGLE_CLIENT_ID = '545789242066-i58a120j7cr90pnpa4lk5b3hlqcsf2li.apps.googleusercontent.com';
export const OUR_PRODUCT = 'No matter your passion, our events cater to all tastes. Discover enriching experiences and secure your tickets for memories that last a lifetime';
export const UPCOMING_EVENTS = 'Stay ahead of the curve with our upcoming events lineup. From exclusive premieres to community gatherings, mark your calendar for unforgettable moments';
export const STRIPE_KEY_LIVE = 'pk_live_B9kbqXGRKbNCgIz7PJ0XQDXy00e6vjEnS9';
export const STRIPE_KEY_TEST = 'pk_test_BRBFA0vPVC1uQBquqiarNlWO00XD57JuLp';
export const ADDRESS = '3152 W. Devon Ave., C-3, Chicago IL 60659';
export const MAP_LINK = 'https://www.google.com/maps/place/3152+W+Devon+Ave+c+3,+Chicago,+IL+60659,+USA/@41.9975926,-87.7116333,17z/data=!3m1!4b1!4m5!3m4!1s0x880fce3d119224e7:0xae30278516c506f3!8m2!3d41.9975926!4d-87.7090584?entry=ttu';
export const CONTACTNO = '+17735526083';
export const EMAIL_ADDRESS = 'advt@hiindia.com';