export const colors = {

     // login page colors
     loginBackground: "#3C0004",
     loginViewBorder: "#373833",
     title: "#9D9797",
     textInputBackground: "#000000",
     textInputBorder: "#656565",
     icon: "#696969",
     buttonColor: "#AA010A",
     red: "red",

     // home
     eventBackground: "#212121",
     eventLocation: "#ACACAC",

     // ticket 
     ticketBackground:"#161616",
     ticketTotalBackground:"#2E2E2E",

     // header
     headerBackgroundcolor: "#E2E0CB",
     // footer
     footerBackgroundColor: "#BDB89B",
     footerSubViewColor: "#02225D",
     footerBorderColor: "#17376C",
     footerIconBackgroundColor: "#1A3A6E",
     footerFontColor: "#748299",
     // dashboard
     blogBackgroundColor: '#F3F1E2',
     blogBorderColor: '#BEBCAB',
     questionIconBackgroundColor: "#d5d2b3",
     questionRightArrowColor: "#5f696f",
     soothBorder1: "#d6d1b3",
     soothBorder2: "#c7c4a5",
     soothFont: "#d6d1b3",
     soothBackground: "#f4f1e2",
     reviewStar: "#FC9600",
     shippingAddress: "#8F8F8F",


     backgroundColor: "#D6D1B3",
     imageBackgroundColor: "#E2E0CB",

     themeColor: "#FF3C48",

     textInputColor: "rgba(230, 230, 230, 0.5)",
     black: "#000000",
     darkGrey: "#676767",
     borderColor: "#D1D1D1",
     textInputTextColor: "#010101",
     placeHolderColor: "#B7B7B7",
     white: "#FFFFFF",
     socialBackColor: "rgba(230, 230, 230, 0.5)",
     categoryText: "#3C3C3C",
     activeRatingStar: "#E9A538",
     inactiveRatingStar: "#D5D5D5",
     messageReceive: "#DCDCDC",
     messageSend: "#FF3C48"
};