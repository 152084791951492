import React, { useEffect, useRef } from 'react';

const PdfGenerateScreen = ({ navigation, route }) => {



    const event_name = route.params.paramEvenetName
    const event_date = route.params.paramEvenetDate
    const event_time = route.params.paramEvenetTime
    const event_orderid = route.params.paramEvenetOrderId
    const event_total = route.params.paramEvenetTotal
    const event_tickets = route.params.paramEvenetTickets

    useEffect(()=>{
        generatePDF();
    },[]);

    const contentRef = useRef();

    const generatePDF = () => {
        const content = contentRef.current;

        if (!content) {
            console.error("Content not found.");
            return;
        }

        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
      <html>
        <head>
          <title>PDF</title>
        </head>
        <body>
          ${content.innerHTML}
        </body>
      </html>
    `);

        printWindow.document.close();
        printWindow.print();
    };

    return (
        <div>
            <button onClick={generatePDF}>Generate PDF</button>
            <div ref={contentRef}>
                <h1>{event_name}</h1>
                <p>Event Date: {event_date}</p>
                <p>Event Time: {event_time}</p>
                <p>Transaction Id: {event_orderid}</p>
                <p>Total : {event_total}</p>
                <table>
                    <tr>
                    <th>Booking ID</th>
                    <th>Type</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    </tr>
                {event_tickets.map((item, index) =>
                        <tr>
                        <td>{item.booking_id}</td>
                        <td>{item.ticket_title}</td>
                        <td>{item.quantity}</td>
                        <td>{item.ticket_price}</td>
                        </tr>
                )}
                </table>
            </div>
        </div>
    );
};

export default PdfGenerateScreen;