import React, { useState, useEffect } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image, } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { colors } from '../common/color';
import { useGoogleLogin } from '@react-oauth/google';
import { LOGIN, SENDOTP } from '../common/webUtils';
import { ACCESS_TOKEN, CUSTOMER_ID, EMAIL, NAME, PHONE, SOCIAL_LOGIN, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import { ids, styles } from '../common/responsive';


function LoginScreen({ navigation, route }) {
    // var companyId = "", result = "";
    // var userDetails = "";
    // useEffect(() => {
    //     if (route.params) {
    //         userDetails = decodeURIComponent(route.params.id);
    //         userDetails = JSON.parse(userDetails);
    //     }
    //     // CustomConsole("open screen");
    // }, []);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // sign in
    const signInViaSimple = () => {

        if (email.trim().length == 0) {
            alert("Enter email");
        }
        else if (!validateEmail(email.trim())) {
            alert("Invalid email");
        }
        else if (password.trim().length == 0) {
            alert("Enter password");
        }
        // else if (password.trim().length < 8) {
        //     alert("Please enter password more than 8 characters");
        // }
        else {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "email": email,
                    "password": password,
                    "social_login": "0"
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(LOGIN + "\n" + raw);

                setLoading(true);
                fetch(LOGIN, requestOptions)
                    .then(response => response.json())
                    .then((json) => {

                        CustomConsole(json);
                        if (json.status == 1) {
                            //store deatils

                            saveSession(USER_ID, json.user_id);
                            saveSession(NAME, json.name);
                            saveSession(PHONE, json.phone);
                            saveSession(SOCIAL_LOGIN, json.social_login);
                            saveSession(EMAIL, json.email);
                            saveSession(CUSTOMER_ID, json.customer_id);

                            setLoading(false);
                            navigation.navigate('HomeScreen');
                            navigation.reset({
                                index: 0,
                                routes: [{ name: 'HomeScreen' }],
                            });
                        } else if (json.status == 2) {

                            var myHeaders1 = new Headers();
                            myHeaders1.append("Content-Type", "application/json");

                            var raw1 = JSON.stringify({
                                "user_id": json.user_id
                            });

                            var requestOptions1 = {
                                method: 'POST',
                                headers: myHeaders1,
                                body: raw1,
                                redirect: 'follow'
                            };

                            CustomConsole(SENDOTP + "\n" + raw1);

                            fetch(SENDOTP, requestOptions1)
                                .then(response1 => response1.json())
                                .then((json1) => {

                                    setLoading(false);
                                    if (json1.status == 1) {
                                        navigation.navigate('OtpScreen', {
                                            paramUserId: json.user_id,
                                            paramData: json,
                                            paramFrom: "login"
                                        });
                                    } else {
                                        alert(json.message);
                                    }
                                })
                                .catch((error1) => {
                                    setLoading(false);
                                    CustomConsole("Send otp Error=>" + error1);
                                });

                        }
                        else {
                            setLoading(false);
                            alert(json.message);

                        }

                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    const login = useGoogleLogin({
        // CustomConsole("SUccess=====>"+JSON.stringify(codeResponse))
        onSuccess: codeResponse => getUserSocialData(codeResponse.access_token),
        flow: 'implicit',
    });

    const getUserSocialData = (access_token) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch("https://www.googleapis.com/oauth2/v1/userinfo?access_token=" + access_token, requestOptions)
                .then(response => response.json())
                .then((json) => {

                    CustomConsole(json);
                    // alert(JSON.stringify(json));


                    var myHeaders1 = new Headers();
                    myHeaders1.append("Content-Type", "application/json");

                    var raw1 = JSON.stringify({
                        "email": json.email,
                        "password": "",
                        "social_login": "1",
                        "access_token": access_token,
                        "name": json.name
                    });

                    var requestOptions1 = {
                        method: 'POST',
                        headers: myHeaders1,
                        body: raw1,
                        redirect: 'follow'
                    };

                    CustomConsole(LOGIN + "\n" + raw1);

                    setLoading(true);
                    fetch(LOGIN, requestOptions1)
                        .then(response1 => response1.json())
                        .then((json1) => {

                            CustomConsole(json1);
                            if (json1.status == 1) {
                                //store deatils

                                saveSession(USER_ID, json1.user_id);
                                saveSession(NAME, json1.name);
                                saveSession(PHONE, json1.phone);
                                saveSession(SOCIAL_LOGIN, json1.social_login);
                                saveSession(EMAIL, json1.email);
                                saveSession(ACCESS_TOKEN, json1.access_token);
                                saveSession(CUSTOMER_ID, json1.customer_id);

                                setLoading(false);
                                navigation.navigate('HomeScreen');
                                navigation.reset({
                                    index: 0,
                                    routes: [{ name: 'HomeScreen' }],
                                });
                            } else {
                                setLoading(false);
                                alert(json1.message);

                            }

                        })
                        .catch((error) => {
                            setLoading(false);
                            CustomConsole("Error=>" + error);
                        });

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Exception=>" + e + "");
        }
    }


    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View>
                        <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                            <Text style={styles.viewTitle}>Login</Text>

                            {/* email */}
                            <Text style={styles.textInputTitle}>Email</Text>
                            <View style={styles.textInputView}>
                                <TextInput
                                    style={styles.textInput}
                                    value={email}
                                    onChangeText={txt => { setEmail(txt) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Email"
                                    dataSet={{ media: ids.textInput }}
                                />
                                <Image source={require("../assets/email2.png")} style={styles.textInputIcon} />
                            </View>

                            {/* password */}
                            <Text style={styles.textInputTitle}>Password</Text>
                            <View style={styles.textInputView}>
                                <TextInput
                                    style={styles.textInput}
                                    value={password}
                                    onChangeText={txt => { setPassword(txt) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Password"
                                    secureTextEntry={!passwordShow}
                                    dataSet={{ media: ids.textInput }}
                                />
                                {/* <Image source={require("../assets/password.png")} style={styles.textInputIcon} /> */}
                                <Pressable onPress={() => { setPasswordShow(!passwordShow) }}>
                                    <Image source={passwordShow ? require("../assets/eye_show.png") : require("../assets/eye_hide.png")}
                                        style={styles.textInputIcon} dataSet={{ media: ids.textInputIcon }} />
                                </Pressable>
                            </View>

                            {/* forgot password view */}
                            <View style={styles.forgotPasswordContainer}>
                                <Pressable onPress={() => navigation.navigate("ForgotPasswordScreen")}>
                                    <Text style={styles.forgotPasswordText}>Forgot Password ?</Text>
                                </Pressable>
                            </View>
                            {/* end of forgot password view */}

                            {/* sign up button view */}
                            <Pressable style={styles.commonButtonView} onPress={signInViaSimple}>
                                <Text style={styles.commonButtonText}>Login</Text>
                            </Pressable>
                            {/* end of sign up button view */}

                            {/* login with google */}
                            <Pressable style={styles.googleButtonView}
                                onPress={() => {
                                    login();
                                }}>
                                <Image source={require("../assets/google_plus.png")} style={styles.googleIcon} />
                                <Text style={styles.commonButtonText}>Continue With Google</Text>
                            </Pressable>
                            {/* end of login with google */}

                            {/* login link view */}
                            <View style={styles.bottomContainer}>
                                <Text style={styles.colorBlackText}>Don't have an account? </Text>
                                <Pressable onPress={() => navigation.navigate("RegisterScreen")}>
                                    <Text style={styles.createAccountText}>sign up</Text>
                                </Pressable>
                            </View>
                            {/* end of login link view */}

                        </View>
                    </View>
                    {/* end of main view */}

                    {/* google sign in button view */}
                    {/* <Pressable style={styles.commonButtonView} onPress={() => login()}>
                            <Text style={styles.commonButtonText}>Google Login</Text>
                        </Pressable> */}
                    {/* <View style={{ alignSelf: "center", marginTop: 15 }}> */}
                    {/* <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}> */}
                    {/* <GoogleLogin
                                onSuccess={credentialResponse => {
                                    CustomConsole("SuccessLogin================================");
                                    CustomConsole(JSON.stringify(credentialResponse));
                                    CustomConsole(credentialResponse);
                                    // fetch(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                                    //     headers: {
                                    //         Authorization: `Bearer ${user.access_token}`,
                                    //         Accept: 'application/json'
                                    //     }
                                    // })
                                    //     .then((res) => {
                                    //         setProfile(res.data);
                                    //     })
                                    //     .catch((err) => CustomConsole(err));
                                }}
                                onError={() => {
                                    CustomConsole('Login Failed');
                                }}
                            /> */}
                    {/* <Button onPress={() => {
                                CustomConsole('Login Success');
                                login();
                            }}>
                                Sign in with Google 🚀
                            </Button>; */}
                    {/* </GoogleOAuthProvider> */}
                    {/* </View> */}

                    {/* end of google sign in button view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default LoginScreen;