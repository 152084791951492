import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { EVENTBOOKING } from '../common/webUtils';
import { CustomConsole, CustomProgressBar } from '../common/utils';
import { CUSTOMER_ID, GUEST_USER_ID, USER_ID, getSession } from '../common/LocalStorage';
import { useState } from 'react';
import { styles, ids, getPlusJakartaSansBoldFont } from '../common/responsive';
import { colors } from '../common/color';
import { StyleSheet } from 'react-native';

const CheckoutForm = ({ eventId, eventTicketType, eventTicketTotal, navigation }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);

    CustomConsole(eventId);
    CustomConsole(eventTicketType);
    CustomConsole(eventTicketTotal);

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: 'if_required'
            // confirmParams: {
            //     return_url: "https://example.com/order/123/complete",
            // },
        });

        CustomConsole(result);
        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            CustomConsole(result.error.message);
            alert(result.error.message);
        } else {
            CustomConsole("Successfull======> ");
            CustomConsole(result.paymentIntent.id);

            // const customer_id = await getSession(CUSTOMER_ID);
            // var user_id = "";

            // if (await getSession(USER_ID) !== null) {
            //     user_id = await getSession(USER_ID);
            // } else {
            //     user_id = await getSession(GUEST_USER_ID);
            // }
            const user_id = await getSession(USER_ID);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "event_id": eventId,
                "user_id": user_id,
                "currency": "USD",
                "payment_type": "online",
                "transaction_id": result.paymentIntent.id,
                "event_ticket_type": eventTicketType,
                "total": eventTicketTotal
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(EVENTBOOKING + "\n" + raw);

            setLoading(true);
            fetch(EVENTBOOKING, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.status == 1) {
                        setLoading(false);
                        alert(json.message);
                        navigation.navigate('ThankyouScreen');
                        navigation.reset({
                            index: 0,
                            routes: [{ name: 'ThankyouScreen' }],
                        });
                    } else {
                        setLoading(false);
                        alert(json.message);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Error=>" + error);
                });

            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <form onSubmit={handleSubmit}>

            {/* progress view */}
            {loading ? CustomProgressBar(loading) : null}
            {/* end of progress view */}

            <PaymentElement />
            <button disabled={!stripe} style={{
                borderRadius: 10,
                height: 60,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.buttonColor,
                marginTop: 22,
                paddingLeft: 50,
                paddingRight: 50,

                color: colors.white,
                fontSize: 20,
                textAlign: 'center',
                fontFamily: getPlusJakartaSansBoldFont(),
                borderWidth: 0
            }} >Submit</button>
        </form>
    )
};


export default CheckoutForm;