import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Alert, Image } from 'react-native';
import { HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { colors } from '../common/color';
import { useIsFocused } from '@react-navigation/native';
import { USER_ID, getSession } from '../common/LocalStorage';
import { externalStyles } from '../common/style';

function FavoriteEventsScreen({ navigation }) {

    const [userId, setUserId] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    return (
        <View>
            <StatusBar barStyle="dark-content" />
            <ScrollView style={externalStyles.container} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}


                </View>
            </ScrollView>

        </View>
    );
};

export default FavoriteEventsScreen;