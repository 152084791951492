import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Pressable, Image, ImageBackground, FlatList } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, } from '../common/utils';
import { ADDFAVORITEEVENT, EVENTDETAILS, GUESTCHECKOUT, } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { ACCESS_TOKEN, CUSTOMER_ID, EMAIL, GUEST_CUSTOMER_ID, GUEST_USER_ID, NAME, PHONE, SOCIAL_LOGIN, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import moment from 'moment';
import { getPlusJakartaSansRegularFont, ids, styles } from '../common/responsive';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import RenderHtml from 'react-native-render-html';
import { useWindowDimensions } from 'react-native';
import { Modal } from 'react-native';
import { TextInput } from 'react-native';
import { colors } from '../common/color';

function EventDetailsScreen({ navigation, route }) {

    const { width } = useWindowDimensions();
    var onlyDigitReg = /^\d+$/;

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [selectedFaq, setSelectedFaq] = useState('0');
    const [eventTitle, setEventTitle] = useState('');
    const [eventState, setEventState] = useState('');
    const [eventTicketRange, setEventTicketRange] = useState('');
    const [eventDay, setEventDay] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventTime, setEventTime] = useState('');
    const [eventEndTime, setEventEndTime] = useState('');
    const [eventLocation, setEventLocation] = useState('');
    const [eventPrice, setEventPrice] = useState('');
    const [eventQrCode, setEventQrCode] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventTerm_n_condition, setEventTerm_n_condition] = useState('');
    const [eventIs_favorite, setEventIs_favorite] = useState('');
    const [eventDisable, setEventDisable] = useState(false);
    const [eventFaqs, setEventFaqs] = useState([]);
    const [eventImages, setEventImages] = useState([]);
    const [ticket_details, setTicket_details] = useState([]);
    const [buyNowShow, setBuyNowShow] = useState(false);
    const [timeZone, setTimeZone] = useState('');
    const [eventVideoUrl, setEventVideoUrl] = useState('');
    const [guestCheckOutModalVisible, setGuestCheckOutModalVisible] = useState(false);
    const [phoneNo, setPhoneNo] = useState('');
    const [fullName, setFullName] = useState('');

    // dialog box 
    const showGuestCheckoutDialog = () => {
        setGuestCheckOutModalVisible(true)
    };
    const hideGuestCheckoutDialog = () => setGuestCheckOutModalVisible(false);

    const event_id = route.params.paramsEventId;

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            eventDetailsApi(user_id == null ? "" : user_id);
        } catch (error) {

        }
    }

    // events Details list api
    const eventDetailsApi = async (user_id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "user_id": user_id,
                "event_id": event_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            CustomConsole(EVENTDETAILS);

            setLoading(true);
            fetch(EVENTDETAILS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.data.length != 0) {

                        setEventDisable(json.data[0].event_status == 0 ? true : false);

                        setEventTitle(json.data[0].event_title);
                        setEventState(json.data[0].state);
                        setEventDay(json.data[0].event_day);
                        setEventTicketRange(json.data[0].ticket_price_range[0] + "-" + json.data[0].ticket_price_range[1]);
                        setEventImages(json.data[0].event_images);
                        // if (json.data[0].youtube_url.includes("embed")) {
                        //     setEventVideoUrl(json.data[0].youtube_url);
                        // } else if(json.data[0].youtube_url.includes("=")){
                        //     var url = json.data[0].youtube_url.split("=")[1];
                        //     setEventVideoUrl("https://www.youtube.com/embed/"+url);
                        // } else if(json.data[0].youtube_url.includes("shorts/")){
                        //     var url = json.data[0].youtube_url.split("shorts/")[1];
                        //     setEventVideoUrl("https://www.youtube.com/embed/"+url);
                        // }else{
                        //     setEventVideoUrl(json.data[0].youtube_url);
                        // }
                        setEventVideoUrl(json.data[0].youtube_url);
                        // CustomConsole(json.data[0].date+"======");
                        // CustomConsole(moment(json.data[0].date,"YYYY-MM-DD").format("MMMM DD, YYYY")+"======");
                        // setEventDate(moment(new Date(json.data[0].date)).format("MMMM DD, YYYY"));
                        setEventDate(moment(json.data[0].date, "YYYY-MM-DD").format("MMMM DD, YYYY"));
                        setEventTime(moment(json.data[0].time, ["h:mm"]).format("hh:mm a"));
                        setEventEndTime(moment(json.data[0].end_time, ["h:mm"]).format("hh:mm a"));
                        setEventLocation(json.data[0].location);
                        setEventPrice(json.data[0].price);
                        setEventQrCode(json.data[0].qr_code);
                        setEventDescription(json.data[0].description);
                        setEventTerm_n_condition(json.data[0].term_n_condition);
                        setEventIs_favorite(json.data[0].is_favorite);
                        setEventFaqs(json.data[0].faqs);
                        setTimeZone(json.data[0].timezone);
                        setTicket_details(json.data[0].ticket_details);
                        const test = json.data[0].ticket_details.every(item => item.remaining_quantity <= 0);
                        // CustomConsole("buy show" + test)
                        setBuyNowShow(test);
                        setLoading(false);
                        // "event_id": 1,
                        //     "event_images": "[\"events\\/November2023\\/1701172867248.webp\",\"events\\/November2023\\/1701172867248.webp\",\"events\\/November2023\\/1701172867248.webp\",\"events\\/November2023\\/1701172867248.webp\",\"events\\/November2023\\/1701172867248.webp\",\"events\\/November2023\\/1701172867248.webp\"]",
                        //         "date": "2025-11-29",
                        //             "time": "08:00:00",
                        //                 "location": "History Museum",
                        //                     "price": "",
                        //                         "qr_code": "",
                        //                             "description": "Resolution diminution conviction so mr at unpleasing simplicity",
                        //                                 "term_n_condition": "",
                        //                                     "is_favorite": "",
                        //                                         "faqs": []
                    } else {
                        setLoading(false);
                        setEventDisable(true);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setEventDisable(true);
                    CustomConsole("Event details Api Error=>" + error);

                });
        } catch (e) {
            setLoading(false);
            setEventDisable(true);
            CustomConsole("Event details Exception=>" + e + "");
        }
    }

    // banner item view
    const bannerItem = (item, index) => {
        return (
            <Image source={{ uri: item }}
                style={styles.eventDetailsBannerImage}
            />
        );
    }

    // add favorite events  api
    const addFavouriteEventApi = async (event_id, is_fav) => {
        try {

            if (userId == '' || userId == null) {
                navigation.navigate("RegisterScreen");
            } else {

                let user_id = await getSession(USER_ID);

                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "user_id": user_id,
                    "event_id": event_id,
                    "is_favourite": is_fav
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(ADDFAVORITEEVENT + "\n" + raw);

                fetch(ADDFAVORITEEVENT, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);

                        if (json.status == 1) {
                            setEventIs_favorite(is_fav);
                            alert(json.message);
                            // var tempCatArr = [...upcomingEventsList]; //copy upcoming events array
                            // var tempEventArrIndex = tempCatArr.findIndex(element => element.event_id === event_id); //find index of image array of particular events
                            // tempCatArr[tempEventArrIndex].is_favorite = is_fav; //assign is_fav value 0/1
                            // setUpcomingEventsList(tempCatArr); //set temparr to upcoming events
                            // setLoading(false);
                        } else {
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        CustomConsole("Error=>" + error);
                        // setLoading(false);
                        alert("Please try again!");
                    });
            }
        } catch (e) {
            CustomConsole("Exception=>" + e + "");
            // setLoading(false);
            alert("Please try again!");
        }
    }

    // guest checkout api
    const guestCheckoutApi = async () => {
        try {

            if (fullName.trim().length == 0) {
                alert("Enter full name");
            }
            else if (phoneNo.trim().length == 0) {
                alert("Enter Mobile Number");
            }
            else if (phoneNo.length != 10) {
                alert("Enter 10-digit Mobile Number");
            }
            else if (!onlyDigitReg.test(phoneNo)) {
                alert("Mobile Number should be only digits");
            } else {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "name": fullName,
                    "phone": phoneNo
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(GUESTCHECKOUT + "\n" + raw);

                fetch(GUESTCHECKOUT, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);

                        if (json.status == 1) {

                            saveSession(GUEST_USER_ID, json.user_id);
                            saveSession(NAME, json.name);
                            saveSession(PHONE, json.phone);
                            saveSession(SOCIAL_LOGIN, json.social_login);
                            saveSession(EMAIL, json.email);
                            // saveSession(ACCESS_TOKEN, json.access_token);
                            saveSession(GUEST_CUSTOMER_ID, json.customer_id);


                            navigation.navigate("TicketSelectionScreen", {
                                paramTicketDetails: ticket_details,
                                paramEventId: event_id,
                                paramEventName: eventTitle,
                                paramEventDate: eventDate,
                                paramEventTime: eventTime,
                                paramEventLocation: eventLocation
                            });
                            hideGuestCheckoutDialog();

                        } else {
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        CustomConsole("Error=>" + error);
                        // setLoading(false);
                        alert("Please try again!");
                    });
            }
        } catch (e) {
            CustomConsole("Exception=>" + e + "");
            // setLoading(false);
            alert("Please try again!");
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={styles.eventDetailsMainView} dataSet={{ media: ids.eventDetailsMainView }}>

                        {eventDisable ?
                            <>
                                <>
                                    {/* back text view */}
                                    <Pressable onPress={() => {
                                        const routes = navigation.getState()?.routes;
                                        const prevRoute = routes[routes.length - 2];

                                        CustomConsole(prevRoute);
                                        if (prevRoute === undefined) {
                                            navigation.navigate("HomeScreen");
                                        } else {
                                            navigation.goBack();
                                        }
                                    }} style={styles.eventDetailsBackView}>
                                        <Image source={require("../assets/left_arrow.png")} style={styles.eventDetailsBackIcon} />
                                        <Text style={styles.eventDetailsBackText} dataSet={{ media: ids.eventDetailsBackText }}>Back to event list</Text>
                                    </Pressable>
                                    {/* end of back text view */}
                                    <View style={styles.emptyStateView}>
                                        <Text style={styles.emptyStateText}>404 Page Not Found</Text>
                                    </View>
                                </>
                            </> :
                            loading
                                ? <View style={{ height: 3000, }}>{loading ? CustomProgressBar(loading) : null}</View> :
                                <>
                                    {/* back text view */}
                                    <Pressable onPress={() => {
                                        const routes = navigation.getState()?.routes;
                                        const prevRoute = routes[routes.length - 2];

                                        CustomConsole(prevRoute);
                                        if (prevRoute === undefined) {
                                            navigation.navigate("HomeScreen");
                                        } else {
                                            navigation.goBack();
                                        }
                                    }} style={styles.eventDetailsBackView}>
                                        <Image source={require("../assets/left_arrow.png")} style={styles.eventDetailsBackIcon} />
                                        <Text style={styles.eventDetailsBackText} dataSet={{ media: ids.eventDetailsBackText }}>Back to event list</Text>
                                    </Pressable>
                                    {/* end of back text view */}

                                    {/* banner image view */}
                                    <View style={styles.eventDetailsBannerView}>
                                        {/* <FlatList
                                        style={styles.eventDetailsBannerView}
                                        data={eventImages}
                                        keyExtractor={(item) => item.id}
                                        showsHorizontalScrollIndicator={false}
                                        horizontal
                                        scrollEventThrottle={32}
                                        pagingEnabled
                                        ItemSeparatorComponent={<View style={styles.bannerSeparator} />}
                                        renderItem={({ item, index }) => bannerItem(item, index)} /> */}
                                        <Carousel
                                            // width={"80%"}
                                            emulateTouch={true}
                                            showArrows={false}
                                            showThumbs={false}
                                            showStatus={false}>
                                            {eventImages.map((item) => {
                                                return (<div>
                                                    <img src={item} style={{
                                                        resize: "block", borderRadius: 30,
                                                        //height: 500
                                                    }} />
                                                    {/* <p className="legend">Legend 1</p> */}
                                                </div>);
                                            })}
                                        </Carousel>
                                    </View>
                                    {/* end of banner image view */}

                                    {/* event title */}
                                    <Text style={styles.eventTitle} dataSet={{ media: ids.eventTitle }}>{eventTitle}</Text>
                                    {/* end of event title */}

                                    {/* time, place & money */}
                                    <View style={styles.eventTimePlaceMoneyView}>
                                        <View style={styles.eventFlexRowView}>
                                            <ImageBackground source={require("../assets/circlebg2.png")}
                                                style={styles.eventTimePlaceMoneyBackground}>
                                                <Image source={require("../assets/calendar.png")}
                                                    style={styles.eventTimePlaceMoneyIcon}
                                                />
                                            </ImageBackground>
                                            <View style={styles.eventTimePlaceMoneyTextView}>
                                                <Text style={styles.eventTimePlaceMoneyTitleText}>{eventDate}</Text>
                                                <Text style={styles.eventTimePlaceMoneySubTitleText} dataSet={{ media: ids.eventTimePlaceMoneySubTitleText }}>{eventDay}, {eventTime} - {eventEndTime}  {"(" + timeZone + ")"}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.eventFlexRowViewWithMarginTop}>
                                            <ImageBackground source={require("../assets/circlebg2.png")}
                                                style={styles.eventTimePlaceMoneyBackground}>
                                                <Image source={require("../assets/location.png")}
                                                    style={styles.eventTimePlaceMoneyIcon}
                                                />
                                            </ImageBackground>
                                            <View style={styles.eventTimePlaceMoneyTextView}>
                                                <Text style={styles.eventTimePlaceMoneyTitleText}>{eventState}</Text>
                                                <Text style={styles.eventTimePlaceMoneySubTitleText} dataSet={{ media: ids.eventTimePlaceMoneySubTitleText }}>{eventLocation}</Text>
                                            </View>
                                        </View>
                                        <View style={styles.eventFlexRowViewWithMarginTop}>
                                            <ImageBackground source={require("../assets/circlebg2.png")}
                                                style={styles.eventTimePlaceMoneyBackground}>
                                                <Image source={require("../assets/ticket.png")}
                                                    style={styles.eventTimePlaceMoneyIcon}
                                                />
                                            </ImageBackground>
                                            <View style={styles.eventTimePlaceMoneyTextView}>
                                                <Text style={styles.eventTimePlaceMoneyTitleText}>{eventTicketRange}</Text>
                                                <Text style={styles.eventTimePlaceMoneySubTitleText} dataSet={{ media: ids.eventTimePlaceMoneySubTitleText }}>Fee is not refundable</Text>
                                            </View>
                                        </View>
                                    </View>
                                    {/* end of time, place & money */}

                                    {!buyNowShow ?
                                        <View style={styles.buynow_checkoutButtonview} dataSet={{ media: ids.buynow_checkoutButtonview }}>
                                            <Pressable onPress={() => {
                                                // CustomConsole("Url===> " + window.location)
                                                if (userId == '' || userId == null) {
                                                    // navigation.navigate("RegisterScreen", { paramEventUrl: window.location });
                                                    navigation.navigate("RegisterScreen", { paramEventUrl: event_id });
                                                } else {
                                                    navigation.navigate("TicketSelectionScreen", {
                                                        paramTicketDetails: ticket_details,
                                                        paramEventId: event_id,
                                                        paramEventName: eventTitle,
                                                        paramEventDate: eventDate,
                                                        paramEventTime: eventTime,
                                                        paramEventLocation: eventLocation
                                                    });
                                                }
                                            }}
                                                style={[styles.commonButtonView, { alignSelf: "flex-start", paddingHorizontal: 20 }]}>
                                                <Text style={styles.commonButtonText}>Buy Ticket</Text>
                                            </Pressable>
                                            <View style={{ margin: 10 }} />
                                            {/* {userId == '' || userId == null ?
                                                <Pressable onPress={() => {
                                                    // showGuestCheckoutDialog();
                                                    navigation.navigate("RegisterScreen", {
                                                        paramTicketDetails: ticket_details,
                                                        paramEventId: event_id,
                                                        paramEventName: eventTitle,
                                                        paramEventDate: eventDate,
                                                        paramEventTime: eventTime,
                                                        paramEventLocation: eventLocation
                                                    });
                                                }}
                                                    style={[styles.commonButtonView, { alignSelf: "flex-start", paddingHorizontal: 20 }]}>
                                                    <Text style={styles.commonButtonText}>Guest checkout</Text>
                                                </Pressable>
                                                : null} */}
                                        </View>
                                        :
                                        <View style={styles.eventNoForBuy}>
                                            <Text style={styles.eventNoForBuytext} dataSet={{ media: ids.eventNoForBuytext }}>"Sorry, tickets for this event are now sold out. Thank you for your interest!"</Text>
                                        </View>}

                                    {/* video */}
                                    {eventVideoUrl != "" && eventVideoUrl != undefined ? <View style={styles.eventVideoView}>
                                        <Carousel
                                            // width={"80%"}
                                            emulateTouch={true}
                                            showArrows={false}
                                            showThumbs={false}
                                            showStatus={false}
                                            showIndicators={false}>
                                            <div>
                                                <iframe width="560" height="400" src={eventVideoUrl} allowFullScreen="true" />
                                            </div>
                                        </Carousel>
                                    </View> : null}
                                    {/* end of video */}

                                    {/* Description view */}
                                    <View style={styles.eventDetailsMarginVertical} dataSet={{ media: ids.eventDetailsMarginVertical }}>
                                        <Text style={styles.eventDetailsDescriptionTitle} dataSet={{ media: ids.eventDetailsDescriptionTitle }}>Description</Text>
                                        <RenderHtml baseStyle={styles.eventDetailsDescriptionSubTitle} dataSet={{ media: ids.eventDetailsDescriptionSubTitle }}
                                            contentWidth={width}
                                            source={{ html: eventDescription }}
                                            // tagsStyles={tagsStyles}
                                            defaultTextProps={{ selectable: true }}
                                            systemFonts={[getPlusJakartaSansRegularFont()]}
                                        />
                                        {/* <Text style={styles.eventDetailsDescriptionSubTitle}dataSet={{media:ids.eventDetailsDescriptionSubTitle}}>{eventDescription}</Text> */}
                                    </View>
                                    {/* end of Description view */}

                                    <View style={styles.eventDetailsSeparator} />

                                    {/* Terms And Condition view */}
                                    <View style={styles.eventDetailsMarginVertical} dataSet={{ media: ids.eventDetailsMarginVertical }}>
                                        <Text style={styles.eventDetailsDescriptionTitle} dataSet={{ media: ids.eventDetailsDescriptionTitle }}>Terms And Condition</Text>
                                        <RenderHtml baseStyle={styles.eventDetailsDescriptionSubTitle} dataSet={{ media: ids.eventDetailsDescriptionSubTitle }}
                                            contentWidth={width}
                                            source={{ html: eventTerm_n_condition }}
                                            // tagsStyles={tagsStyles}
                                            defaultTextProps={{ selectable: true }}
                                            systemFonts={[getPlusJakartaSansRegularFont()]}
                                        />
                                        {/* <Text style={styles.eventDetailsDescriptionSubTitle} dataSet={{ media: ids.eventDetailsDescriptionSubTitle }}>{eventTerm_n_condition}</Text> */}
                                    </View>
                                    {/* end of Terms And Condition view */}

                                    <View style={styles.eventDetailsSeparator} />

                                    {/* FAQ's view */}
                                    <View style={styles.eventDetailsMarginVertical} dataSet={{ media: ids.eventDetailsMarginVertical }}>
                                        <Text style={styles.eventDetailsDescriptionTitle} dataSet={{ media: ids.eventDetailsDescriptionTitle }}>FAQ's</Text>

                                        <FlatList
                                            style={styles.eventDetailsBannerView}
                                            data={eventFaqs}
                                            keyExtractor={(item) => item.id}
                                            renderItem={({ item, index }) => {
                                                return (
                                                    <View style={styles.eventFaqSubView}>
                                                        <Pressable onPress={() => {
                                                            if (selectedFaq == index) {
                                                                setSelectedFaq(null);
                                                            } else {
                                                                setSelectedFaq(index);
                                                            }
                                                        }}
                                                            style={styles.eventFaqHeadingView}>
                                                            <Text style={styles.eventFaqHeadingText}>{item.faq}</Text>
                                                            <Image source={selectedFaq !== index ? require("../assets/down_arrow.png") : require("../assets/up_arrow.png")}
                                                                style={styles.eventFaqIcon} />
                                                        </Pressable>
                                                        {selectedFaq == index ?
                                                            <View style={styles.eventFaqDetailView}>
                                                                <Text style={styles.eventFaqDetailText}>{item.ans}</Text>
                                                            </View> : null}
                                                    </View>
                                                );
                                            }} />

                                        {/* FAQ Item View */}
                                        {/* <View style={styles.eventFaqView}> */}

                                        {/* <View style={styles.eventFaqSubView}>
                                    <Pressable onPress={() => {
                                        if (selectedFaq == "0") {
                                            setSelectedFaq(null);
                                        } else {
                                            setSelectedFaq("0");
                                        }
                                    }}
                                        style={styles.eventFaqHeadingView}>
                                        <Text style={styles.eventFaqHeadingText}>Dummy text ever since the when </Text>
                                        <Image source={selectedFaq !== "0" ? require("../assets/down_arrow.png") : require("../assets/up_arrow.png")}
                                            style={styles.eventFaqIcon} />
                                    </Pressable>
                                    {selectedFaq == "0" ?
                                        <View style={styles.eventFaqDetailView}>
                                            <Text style={styles.eventFaqDetailText}>Eessentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.</Text>
                                        </View> : null}
                                </View> */}

                                        {/* <View style={styles.eventFaqSubView}>
                                    <Pressable onPress={() => {
                                        if (selectedFaq == "1") {
                                            setSelectedFaq(null);
                                        } else {
                                            setSelectedFaq("1");
                                        }

                                    }}
                                        style={{ paddingVertical: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Text style={{ fontSize: 30, color: colors.white, textAlign: "left", fontWeight: "bold" }}>Dummy text ever since the when </Text>
                                        <Image source={selectedFaq !== "1" ? require("../assets/down_arrow.png") : require("../assets/up_arrow.png")}
                                            style={{
                                                marginHorizontal: 9,
                                                left: 5,
                                                height: 20,
                                                width: 20,
                                                resizeMode: "contain",
                                                tintColor: colors.white

                                            }} />
                                    </Pressable>
                                    {selectedFaq == "1" ?
                                        <View style={{ paddingBottom: 50, paddingTop: 20 }}>
                                            <Text style={{ fontSize: 25, color: colors.title, textAlign: "left", }}>Eessentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.</Text>
                                        </View> : null}
                                </View> */}

                                        {/* </View> */}
                                        {/* End of FAQ Item View */}

                                    </View>
                                    {/* end of FAQ's view */}

                                    {/* QR code */}
                                    <View style={styles.eventQrCodeMainView}>
                                        <Text style={styles.eventQrScanMeText}>Scan Me</Text>
                                        <Image source={{ uri: eventQrCode }}
                                            style={styles.eventQrImage} />
                                        <Text style={styles.eventTicketRange}>Ticket range: {eventTicketRange}</Text>

                                        <Pressable style={styles.eventFavoriteButtonView}
                                            onPress={() => addFavouriteEventApi(event_id, (eventIs_favorite == "" || eventIs_favorite == "0") ? "1" : "0")} >
                                            <Image source={require("../assets/like.png")} style={(eventIs_favorite == "" || eventIs_favorite == "0") ? styles.eventFavoriteIconInActive : styles.eventFavoriteIconActive} />
                                            <Text style={styles.eventFavoriteButtonText}>Favorite</Text>
                                        </Pressable>
                                    </View>
                                    {/* end QR code */}
                                </>}

                    </View>
                    {/* end of main view */}

                    {/* guest checkout modal */}
                    <Modal
                        onRequestClose={hideGuestCheckoutDialog}
                        transparent
                        visible={guestCheckOutModalVisible}
                        // visible={true}
                        animationType={'slide'}>
                        <View style={styles.guestCheckoutModalMainView}>
                            <View style={[styles.guestCheckoutModalSubView, 0]}>



                                <View style={styles.guestCheckoutMainView} dataSet={{ media: ids.guestCheckoutMainView }}>
                                    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                                        <Text style={styles.guestTitle}>Guest Checkout</Text>
                                        <Pressable onPress={hideGuestCheckoutDialog} style={styles.guestCheckoutModalCloseButtonView}>
                                            <Image source={require("../assets/modal_close.png")} style={styles.guestCheckoutModalCloseButtonImage} />
                                        </Pressable>
                                    </View>

                                    {/* full name */}
                                    <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Full Name</Text>
                                    <View style={styles.textInputView}>
                                        <TextInput
                                            style={styles.textInput}
                                            value={fullName}
                                            onChangeText={txt => { setFullName(txt) }}
                                            placeholderTextColor={colors.placeHolderColor}
                                            placeholder="Enter Full Name"
                                            dataSet={{ media: ids.textInput }}
                                        />
                                        <Image source={require("../assets/user.png")} style={styles.textInputIcon} />
                                    </View>

                                    {/* mobile number */}
                                    <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Mobile Number</Text>
                                    <View style={styles.textInputView}>
                                        <TextInput
                                            style={styles.textInput}
                                            value={phoneNo}
                                            onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')) }}
                                            placeholderTextColor={colors.placeHolderColor}
                                            // maxLength={10}
                                            keyboardType='numeric'
                                            inputMode='numeric'
                                            placeholder="Enter Mobile Number"
                                            dataSet={{ media: ids.textInput }}
                                        />
                                        <Image source={require("../assets/mobile2.png")} style={styles.textInputIcon} />
                                    </View>

                                    {/* continue button view */}
                                    <Pressable style={styles.commonButtonView} onPress={() => guestCheckoutApi()}>
                                        <Text style={styles.commonButtonText} dataSet={{ media: ids.commonButtonText }}>Continue</Text>
                                    </Pressable>
                                    {/* end of continue button view */}

                                </View>
                            </View>
                        </View>
                    </Modal>
                    {/* end of guest checkout modal */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default EventDetailsScreen;
