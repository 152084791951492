// https://react-native-async-storage.github.io/async-storage/docs/usage/
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CustomConsole } from './utils';

export const saveSession = async (key, value) => {
    try {
        await AsyncStorage.setItem(key, value);
        CustomConsole("Key=>" + key + ":" + "Value=>" + value);
    } catch (e) {
        // saving error
    }
}

export const clearAsyncStorage = async () => {
    AsyncStorage.clear();
}

export const getSession = async (key) => {
    var value = "";
    try {
        value = await AsyncStorage.getItem(key)
        CustomConsole("Key=>" + key + ":" + "Value=>" + value);
    } catch (e) {
        // error reading value
        // CustomConsole("Error=>" + e);
    }
    return value;
}

// User Data
export const USER_ID = "user_id";
export const NAME = "name";
export const PHONE = "phone";
export const EMAIL = "email";
export const SOCIAL_LOGIN = "social_login";
export const COUNTRY_CODE = "country_code";
export const COUNTRY_NAME = "country_name";
export const COUNTRY_ICON = "country_icon";
export const ACCESS_TOKEN = "access_token";
export const CUSTOMER_ID = "curtomer_id";

export const OTP_VERIFY = "otp_verify";
export const PASSWORD = "password";
export const FCM_TOKEN = "fcm_token";

export const GUEST_USER_ID = "guest_user_id";
export const GUEST_CUSTOMER_ID = "guest_customer_id";
