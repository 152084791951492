import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image, ImageBackground, FlatList, Dimensions, Linking } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, dateYear, getFormatDate, getMonthName, getOnlyDateNo, getPlusJakartaSansBoldFont, getPlusJakartaSansSemiBoldFont, scrollToTop, validateEmail } from '../common/utils';
import { colors } from '../common/color';
import { Button, Dialog, Portal, Provider } from 'react-native-paper';
import { USER_ID, clearAsyncStorage, getSession } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import { ADDFAVORITEEVENT, FAVORITEEVENTS, GETPROFILE, TICKETDETAILS, TICKETLIST, UPDATEPROFILE } from '../common/webUtils';
import moment from 'moment';
import { Modal } from 'react-native';
import { ids, styles } from '../common/responsive';
// import RNHTMLtoPDF from 'react-native-html-to-pdf';

function ProfileScreen({ navigation, route }) {

    var onlyDigitReg = /^\d+$/;

    const [eventName, setEventName] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [eventTime, setEventTime] = useState('');
    const [commonOrderId, setCommonOrderId] = useState('');
    const [total, setTotal] = useState('');


    const [visible, setVisible] = useState(false);
    const [phoneNo, setPhoneNo] = useState('');
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [selectedTab, setSelectedTab] = useState('Profile');
    const [userId, setUserId] = useState('');
    const [loading, setLoading] = useState(false);
    const [ticketList, setTicketList] = useState([]);
    const [favoriteEventsList, setFavoriteEventsListList] = useState([]);
    const [ticketDetailsList, setTicketDetailsList] = useState([]);

    const [ticketDetailsModal, setTicketDetailsModal] = React.useState(false);
    // dialog box 
    const showTicketDetailsDialog = (ticket_id, user_id, event_title, eventDateNo, eventMonth, eventYear, eventStartTime, eventEndTime, netPrice) => {

        setCommonOrderId(ticket_id);
        setEventName(event_title);
        setEventDate(eventDateNo + " " + eventMonth + "," + eventYear);
        setEventTime(eventStartTime + " - " + eventEndTime);
        setTotal(netPrice);

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "user_id": user_id,
            "common_order": ticket_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        CustomConsole(TICKETDETAILS + "\n" + raw);
        fetch(TICKETDETAILS, requestOptions)
            .then(response => response.json())
            .then((json) => {
                CustomConsole(json);

                ticketDetailsList.length = 0;
                for (let i = 0; i < json.data.length; i++) {

                    ticketDetailsList.push({
                        index: i,
                        booking_id: json.data[i].booking_id,
                        customer_id: json.data[i].customer_id,
                        organiser_id: json.data[i].organiser_id,
                        event_id: json.data[i].event_id,
                        ticket_id: json.data[i].ticket_id,
                        quantity: json.data[i].quantity,
                        price: json.data[i].price,
                        tax: json.data[i].tax,
                        net_price: json.data[i].net_price,
                        status: json.data[i].status,
                        event_title: json.data[i].event_title,
                        event_start_date: json.data[i].event_start_date,
                        event_end_date: json.data[i].event_end_date,
                        event_start_time: json.data[i].event_start_time,
                        event_end_time: json.data[i].event_end_time,
                        event_repetitive: json.data[i].event_repetitive,
                        ticket_title: json.data[i].ticket_title,
                        ticket_price: json.data[i].ticket_price,
                        event_category: json.data[i].event_category,
                        booking_cancel: json.data[i].booking_cancel,
                        item_sku: json.data[i].item_sku,
                        order_number: json.data[i].order_number,
                        transaction_id: json.data[i].transaction_id,
                        customer_name: json.data[i].customer_name,
                        customer_email: json.data[i].customer_email,
                        currency: json.data[i].currency,
                        checked_in: json.data[i].checked_in,
                        payment_type: json.data[i].payment_type,
                        is_paid: json.data[i].is_paid,
                        is_bulk: json.data[i].is_bulk,
                        checked_in_time: json.data[i].checked_in_time,
                        common_order: json.data[i].common_order,
                        download_pdf: json.data[i].download_pdf,

                        dateNo: getOnlyDateNo(json.data[i].event_start_date),
                        month_name: getMonthName(json.data[i].event_start_date),
                        year: dateYear(json.data[i].event_start_date),
                    });
                }

                setTicketDetailsList(ticketDetailsList);
                setTicketDetailsModal(true)

            })
            .catch((error) => {
                setLoading(false);
                CustomConsole("Ticket Info Api Error=>" + error);
            });

    };
    const hideTicketDetailsDialog = () => setTicketDetailsModal(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
        if (route.params) {
            setSelectedTab(route.params.paramActiveTab);

        } else {
            setSelectedTab('Profile');

        }
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            getProfileInfoApi(user_id);
        } catch (error) {

        }
    }

    const showDialog = () => {
        setVisible(true);
    };

    const hideDialog = () => setVisible(false);

    // get profile info api
    const getProfileInfoApi = async (user_id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "user_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(GETPROFILE + "\n" + raw);

            setLoading(true);
            fetch(GETPROFILE, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    setFullName(json.name);
                    setEmail(json.email);
                    setPhoneNo(json.phone);

                    ticketListApi(user_id);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Profile Info Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Profile Info Exception=>" + e + "");
        }
    }

    // ticket list api
    const ticketListApi = async (user_id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "user_id": user_id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(TICKETLIST);

            // setLoading(true);
            fetch(TICKETLIST, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    ticketList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        ticketList.push({
                            index: i,
                            booking_id: json.data[i].booking_id,
                            customer_id: json.data[i].customer_id,
                            event_id: json.data[i].event_id,
                            quantity: json.data[i].quantity,
                            net_price: json.data[i].net_price,
                            event_title: json.data[i].event_title,
                            event_start_date: json.data[i].event_start_date,
                            event_end_date: json.data[i].event_end_date,
                            event_start_time: moment(json.data[i].event_start_time, ["h:mm"]).format("hh:mm a"),
                            event_end_time: moment(json.data[i].event_end_time, ["h:mm"]).format("hh:mm a"),
                            customer_name: json.data[i].customer_name,
                            customer_email: json.data[i].customer_email,
                            common_order: json.data[i].common_order,

                            created_at: getFormatDate(json.data[i].created_at),

                            dateNo: getOnlyDateNo(json.data[i].event_start_date),
                            month_name: getMonthName(json.data[i].event_start_date),
                            year: dateYear(json.data[i].event_start_date),
                        });
                    }

                    setTicketList(ticketList);
                    favoriteEventsListApi(user_id);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Ticket List Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Ticket List Exception=>" + e + "");
        }
    }

    // favorite events list api
    const favoriteEventsListApi = async (user_id) => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "user_id": user_id,
                "page_size": "1000",
                "page_number": "1"
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(FAVORITEEVENTS + "\n" + raw);

            // setLoading(true);
            fetch(FAVORITEEVENTS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    favoriteEventsList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        favoriteEventsList.push({
                            index: i,
                            event_id: json.data[i].event_id,
                            event_title: json.data[i].event_title,
                            event_images: json.data[i].event_images,
                            date: json.data[i].date,
                            dateNo: getOnlyDateNo(json.data[i].date),
                            month_name: getMonthName(json.data[i].date),
                            time: json.data[i].time,
                            location: json.data[i].location,
                            price: json.data[i].ticket_price_range[0] + "-" + json.data[i].ticket_price_range[1],
                            qr_code: json.data[i].qr_code,
                            description: json.data[i].description,
                            term_n_condition: json.data[i].term_n_condition,
                            is_favorite: json.data[i].is_favorite,
                            faqs: json.data[i].faqs,
                        });
                    }

                    setFavoriteEventsListList(favoriteEventsList);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Favorite Events List Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Favorite Events List Exception=>" + e + "");
        }
    }

    // update profile
    const updateProfileApi = () => {

        if (fullName.trim().length == 0) {
            alert("Enter full name");
        }
        else if (email.trim().length == 0) {
            alert("Enter email");
        }
        else if (!validateEmail(email.trim())) {
            alert("Invalid email");
        }
        else if (phoneNo.trim().length == 0) {
            alert("Enter Mobile Number");
        }
        // else if (phoneNo.length != 10) {
        //     alert("Enter 10-digit Mobile Number");
        // }
        else if (!onlyDigitReg.test(phoneNo)) {
            alert("Mobile Number should be only digits");
        }
        else {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "user_id": userId,
                    "name": fullName,
                    "email": email,
                    "phone": phoneNo
                });

                var requestOptions = {
                    method: 'POST',
                    body: raw,
                    headers: myHeaders,
                    redirect: 'follow'
                };

                CustomConsole(UPDATEPROFILE + "\n" + raw);

                setLoading(true);
                fetch(UPDATEPROFILE, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);
                        if (json.status == 1) {
                            setLoading(false);
                            alert(json.message);
                            setFullName(json.name);
                            setPhoneNo(json.phone);
                            setEmail(json.email);
                        } else {
                            setLoading(false);
                            if (json.error.length > 0) {
                                alert(json.error[0]);
                            }
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }

        }
    }

    // logout function
    const logoutFunction = async () => {
        hideDialog();
        clearAsyncStorage();
        navigation.navigate('LoginScreen');
        navigation.reset({
            index: 0,
            routes: [{ name: 'LoginScreen' }],
        });
    }

    // add favorite events  api
    const addFavouriteEventApi = async (event_id, is_fav) => {
        try {

            let user_id = await getSession(USER_ID);

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "user_id": user_id,
                "event_id": event_id,
                "is_favourite": is_fav
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(ADDFAVORITEEVENT + "\n" + raw);

            setLoading(true);
            fetch(ADDFAVORITEEVENT, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.status == 1) {
                        var tempCatArr = [...favoriteEventsList]; //copy upcoming events array
                        var tempEventArrIndex = tempCatArr.findIndex(element => element.event_id === event_id); //find index of image array of particular events
                        tempCatArr[tempEventArrIndex].is_favorite = is_fav; //assign is_fav value 0/1
                        setFavoriteEventsListList(tempCatArr); //set temparr to upcoming events
                        alert(json.message);
                        favoriteEventsListApi(userId);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        alert(json.message);
                    }

                })
                .catch((error) => {
                    CustomConsole("Error=>" + error);
                    setLoading(false);
                    alert("Please try again!");
                });

        } catch (e) {
            CustomConsole("Exception=>" + e + "");
            setLoading(false);
            alert("Please try again!");
        }
    }

    // favorite events item view
    const favoriteEventsItem = (item, index) => {
        return (
            <Pressable onPress={() => navigation.navigate("EventDetailsScreen", { paramsEventId: item.event_id })}
                style={styles.upcomingEventItemMainView} dataSet={{ media: ids.upcomingEventItemMainView }}>
                <Image source={{ uri: item.event_images[0] }}
                    style={styles.upcomingEventItemImage}
                />
                <Pressable style={styles.upcomingEventItemLikeView} onPress={() => addFavouriteEventApi(item.event_id, (item.is_favorite == "" || item.is_favorite == "0") ? "1" : "0")}>
                    <Image source={require("../assets/like.png")} style={(item.is_favorite == "" || item.is_favorite == "0") ? styles.upcomingEventLikeInActive : styles.upcomingEventLikeActive} />
                </Pressable>
                <View style={styles.upcomingEventSubview}>
                    <ImageBackground source={require("../assets/datebg.png")} style={styles.upcomingEventDateBackground}>
                        <Text style={styles.upcomingEventDateText}>{item.dateNo}</Text>
                        <Text style={styles.upcomingEventDateText}>{item.month_name}</Text>
                    </ImageBackground>
                    <View style={styles.upcomingEventSecondSubview}>
                        <Text style={styles.upcomingEventTitle} numberOfLines={2}>{item.event_title}</Text>
                        <View style={styles.upcomingEventLocation}>
                            <Image source={require("../assets/location.png")} style={styles.upcomingEventLocationIcon} />
                            <Text style={styles.upcomingEventLocationText} numberOfLines={1}>{item.location}</Text>
                        </View>
                        <ImageBackground source={require("../assets/pricebg.png")} style={styles.upcomingEventPriceView} imageStyle={styles.upcomingEventPriceBgImage}>
                            <Image source={require("../assets/ticket.png")} style={styles.upcomingEventPriceIcon} />
                            <Text style={styles.upcomingEventPriceText} numberOfLines={1}>{item.price} /Person</Text>
                        </ImageBackground>
                    </View>
                </View>
            </Pressable>
        );
    }

    // ticket item view
    const ticketsItem = (item, index) => {
        return (
            <View style={styles.ticketItemMainView} dataSet={{ media: ids.ticketItemMainView }}>
                <View style={styles.ticketItemDetailsView} dataSet={{ media: ids.ticketItemDetailsView }}>
                    <Text style={styles.ticketItemDetailsTitle} dataSet={{ media: ids.ticketItemDetailsTitle }}>{item.event_title}</Text>
                    <Text style={styles.ticketItemDetailsText} dataSet={{ media: ids.ticketItemDetailsText }}>{item.dateNo} {item.month_name}, {item.year}</Text>
                    <Text style={styles.ticketItemDetailsText} dataSet={{ media: ids.ticketItemDetailsText }}>{item.event_start_time} To {item.event_end_time}</Text>
                    <Text style={styles.ticketItemDetailsText} dataSet={{ media: ids.ticketItemDetailsText }}>Booking Date: {item.created_at}</Text>
                    <Text style={styles.ticketItemDetailsOrderId} dataSet={{ media: ids.ticketItemDetailsOrderId }}>Booking Id: #{item.booking_id}</Text>
                </View>

                <View style={styles.ticketItemDetailsFlexOne}>
                    <Text style={styles.ticketItemDetailsQtyPrice} dataSet={{ media: ids.ticketItemDetailsQtyPrice }}>{item.quantity}</Text>
                </View>

                <View style={styles.ticketItemDetailsFlexOne}>
                    <Text style={styles.ticketItemDetailsQtyPrice} dataSet={{ media: ids.ticketItemDetailsQtyPrice }}>{item.net_price}</Text>
                </View>

                <Pressable onPress={() => showTicketDetailsDialog(item.common_order, userId, item.event_title, item.dateNo, item.month_name, item.year, item.event_start_time, item.event_end_time, item.net_price)}
                    style={styles.ticketItemDetailsFlexPointFive} dataSet={{ media: ids.ticketItemDetailsFlexPointFive }}>
                    <Text style={styles.ticketItemDetailsPressableText} dataSet={{ media: ids.ticketItemDetailsPressableText }}>Details</Text>
                </Pressable>
            </View>
        );
    }

    const generatePDF = () => {
        // const content = contentRef.current;

        // if (!content) {
        //     console.error("Content not found.");
        //     return;
        // }

        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
      <html>
        <head>
          <title>PDF</title>
        </head>
        <body>
        <div >
        <h1>${eventName}</h1>
        <p>Event Date: ${eventDate}</p>
        <p>Event Time: ${eventTime}</p>
        <p>Transaction Id: ${commonOrderId}</p>
        <p>Total : ${total}</p>
        <table>
            <tr>
            <th>Booking ID</th>
            <th>Type</th>
            <th>Quantity</th>
            <th>Price</th>
            </tr>
        ${ticketDetailsList.map((item, index) =>
            ` <tr>
                <td>${item.booking_id}</td>
                <td>${item.ticket_title}</td>
                <td>${item.quantity}</td>
                <td>${item.ticket_price}</td>
            </tr>`
        ).join("")}
        </table>
    </div>
        </body>
      </html>
    `);

        printWindow.document.close();
        printWindow.print();
    };

    return (
        <Provider>
            <View style={styles.container}>
                {BackgroundView()}
                <StatusBar barStyle="dark-content" />

                <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                    <View style={{}}>

                        {/* progress view */}
                        {loading ? CustomProgressBar(loading) : null}
                        {/* end of progress view */}

                        {/* Header view */}
                        {HeaderComponent("3", navigation, userId)}
                        {/* end of Header view */}

                        {/* main view */}
                        <View style={styles.profileMainView} dataSet={{ media: ids.profileMainView }}>

                            {/* profile tab view */}
                            <View style={styles.profileTabView}>
                                <Pressable onPress={() => setSelectedTab("Profile")}
                                    style={selectedTab == "Profile" ? styles.profileActiveTab : styles.profileInActiveTab}>
                                    <Text style={styles.profileTabText} dataSet={{ media: ids.profileTabText }}>Profile</Text>
                                </Pressable>
                                <Pressable onPress={() => setSelectedTab("Tickets")}
                                    style={selectedTab == "Tickets" ? styles.profileActiveTab : styles.profileInActiveTab}>
                                    <Text style={styles.profileTabText} dataSet={{ media: ids.profileTabText }}>Tickets</Text>
                                </Pressable>
                                <Pressable onPress={() => setSelectedTab("Favorite Events")}
                                    style={selectedTab == "Favorite Events" ? styles.profileActiveTab : styles.profileInActiveTab}>
                                    <Text style={styles.profileTabText} dataSet={{ media: ids.profileTabText }}>Favorite Events</Text>
                                </Pressable>
                                <Pressable onPress={() => showDialog()}
                                    style={selectedTab == "" ? styles.profileActiveTab : styles.profileInActiveTab}>
                                    <Text style={styles.profileTabText} dataSet={{ media: ids.profileTabText }}>Logout</Text>
                                </Pressable>
                            </View>
                            {/* end of profile tab view */}

                            {/* main view */}
                            {selectedTab == "Profile" ?
                                <>
                                    {/* profile view */}
                                    <View style={styles.profileSubView} dataSet={{ media: ids.profileSubView }}>
                                        <Text style={styles.personalDetailsText}>Personal Details</Text>

                                        <View style={styles.personalDetailsView} dataSet={{ media: ids.personalDetailsView }}>
                                            <View style={styles.personalDetailsFirstSubView} dataSet={{ media: ids.personalDetailsFirstSubView }}>
                                                {/* full name */}
                                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Full Name</Text>
                                                <View style={styles.textInputView}>
                                                    <TextInput
                                                        style={styles.textInput}
                                                        value={fullName}
                                                        onChangeText={txt => { setFullName(txt) }}
                                                        placeholderTextColor={colors.placeHolderColor}
                                                        placeholder="Enter Full Name"
                                                        dataSet={{ media: ids.textInput }}
                                                    />
                                                    <Image source={require("../assets/user.png")} style={styles.textInputIcon} />
                                                </View>
                                            </View>
                                            <View style={styles.personalDetailsSecondSubView} dataSet={{ media: ids.personalDetailsSecondSubView }}>
                                                {/* email */}
                                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Email</Text>
                                                <View style={styles.textInputView}>
                                                    <TextInput
                                                        style={styles.textInput}
                                                        value={email}
                                                        onChangeText={txt => { setEmail(txt) }}
                                                        placeholderTextColor={colors.placeHolderColor}
                                                        placeholder="Enter Email"
                                                        dataSet={{ media: ids.textInput }}
                                                    />
                                                    <Image source={require("../assets/email2.png")} style={styles.textInputIcon} />
                                                </View>
                                            </View>
                                        </View>

                                        <View style={styles.personalDetailsView} dataSet={{ media: ids.personalDetailsView }}>
                                            <View style={styles.personalDetailsFirstSubView} dataSet={{ media: ids.personalDetailsFirstSubView }}>
                                                {/* mobile number */}
                                                <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Mobile Number</Text>
                                                <View style={styles.textInputView}>
                                                    <TextInput
                                                        style={styles.textInput}
                                                        value={phoneNo}
                                                        onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')) }}
                                                        placeholderTextColor={colors.placeHolderColor}
                                                        // maxLength={10}
                                                        keyboardType='numeric'
                                                        inputMode='numeric'
                                                        placeholder="Enter Mobile Number"
                                                        dataSet={{ media: ids.textInput }}
                                                    />
                                                    <Image source={require("../assets/mobile2.png")} style={styles.textInputIcon} />
                                                </View>
                                            </View>
                                            <View style={styles.personalDetailsSecondSubView}>
                                            </View>
                                        </View>

                                        {/* sign up button view */}
                                        <Pressable style={styles.editProfileButton} onPress={updateProfileApi}>
                                            <Text style={styles.editProfileButtonText}>Edit</Text>
                                        </Pressable>
                                        {/* end of sign up button view */}

                                    </View>
                                    {/* end of profile view */}
                                </>
                                :
                                selectedTab == "Tickets" ?
                                    <>
                                        {/* tickets list view */}
                                        <View style={styles.ticketDetailsHeadingView} dataSet={{ media: ids.menuParentView }}>
                                            <View style={styles.ticketDetailsHeadingProfileView} dataSet={{ media: ids.ticketDetailsHeadingProfileView }}>
                                                <Text style={styles.ticketDetailsHeadingProfileText} dataSet={{ media: ids.ticketDetailsHeadingProfileText }}>Profile</Text>
                                            </View>
                                            <View style={styles.ticketDetailsHeadingQtyPriceView} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceView }}>
                                                <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}>Quantity</Text>
                                            </View>
                                            <View style={styles.ticketDetailsHeadingQtyPriceView} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceView }}>
                                                <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}>Total</Text>
                                            </View>
                                            <View style={styles.ticketDetailsHeadingDetailsView} dataSet={{ media: ids.ticketDetailsHeadingDetailsView }}>
                                                <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}>Details</Text>
                                            </View>
                                        </View>

                                        {ticketList.length != 0 ?
                                            <FlatList
                                                style={styles.ticketDetailsList}
                                                data={ticketList}
                                                ItemSeparatorComponent={<View style={styles.ticketSeparator} />}
                                                renderItem={({ item, index }) => ticketsItem(item, index)} />
                                            : !loading ?
                                                <View style={styles.emptyStateView}>
                                                    <Text style={styles.emptyStateText}>Tickets is not available</Text>
                                                </View> : null}

                                        {/* end of tickets list view */}
                                    </>
                                    :
                                    <>
                                        {/* fav events list view */}
                                        {favoriteEventsList.length != 0 ?
                                            // <FlatList style={styles.offerList}
                                            //     data={favoriteEventsList}
                                            //     ItemSeparatorComponent={<View style={styles.offerSeparator} />}
                                            //     renderItem={({ item, index }) => favoriteEventsItem(item, index)} />
                                            <View style={[styles.upcomingEventListView]}>
                                                {favoriteEventsList.map((item, index) => {
                                                    return favoriteEventsItem(item, index);
                                                })}
                                            </View>
                                            : !loading ?
                                                <View style={styles.emptyStateView}>
                                                    <Text style={styles.emptyStateText}>Favorite Events is not available</Text>
                                                </View> : null}
                                        {/* end of fav events list view */}
                                    </>
                            }
                            {/* end of main view */}

                        </View>
                        {/* end of main view */}

                        {/* footer view */}
                        {FooterComponent(navigation)}
                        {/* end of footer view */}

                    </View>
                </ScrollView>

                {/* dialog box */}
                <Portal>
                    <Dialog visible={visible} onDismiss={hideDialog} style={styles.logoutModalMainView}>
                        <Dialog.Title style={styles.logoutModalTitle}>Alert</Dialog.Title>
                        <Dialog.Content>
                            <Text variant="bodyMedium" style={styles.logoutModalSubTitle}>{"Are you sure to logout?"}</Text>
                        </Dialog.Content>
                        <View style={styles.logoutModalButtonView}>
                            <Dialog.Actions>
                                <Button onPress={hideDialog} textColor={colors.white} style={styles.logoutModalButton}>No</Button>
                            </Dialog.Actions>
                            <Dialog.Actions>
                                <Button onPress={logoutFunction} textColor={colors.white} style={styles.logoutModalButton}>Yes</Button>
                            </Dialog.Actions>
                        </View>
                    </Dialog>
                </Portal>
                {/* end of dialog box */}

                {/* ticket details modal */}
                <Modal
                    onRequestClose={hideTicketDetailsDialog}
                    transparent
                    visible={ticketDetailsModal}
                    animationType={'slide'}>
                    <View style={styles.headerCountryModalMainView} dataSet={{ media: ids.headerCountryModalMainView }}>
                        <View style={[styles.headerCountryModalSubView, 500]} dataSet={{ media: ids.headerCountryModalSubView }}>
                            <Pressable onPress={hideTicketDetailsDialog} style={styles.headerCountryModalCloseView}>
                                <Image source={require("../assets/modal_close.png")} style={styles.headerCountryModalCloseImage} />
                            </Pressable>

                            <View style={styles.ticketDetailsModalView}>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketTitleText}>Event name</Text>
                                    <Text style={styles.ticketSubTitleText} dataSet={{ media: ids.ticketSubTitleText }}>{eventName}</Text>
                                </View>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketTitleText}>Event date-time</Text>
                                    <Text style={styles.ticketSubTitleText} dataSet={{ media: ids.ticketSubTitleText }}>{eventDate} - {"(" + eventTime + ")"}</Text>
                                </View>
                            </View>

                            <View style={styles.ticketDetailsModalViewWithMarginTop}>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketTitleText}>Transaction Id</Text>
                                    <Text style={styles.ticketSubTitleText} dataSet={{ media: ids.ticketSubTitleText }}>#{commonOrderId}</Text>
                                </View>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketTitleText}>Total</Text>
                                    <Text style={styles.ticketSubTitleText} dataSet={{ media: ids.ticketSubTitleText }}>{total}</Text>
                                </View>
                            </View>
                            <View style={styles.ticketDetailsHeadingView}>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}>Booking Id</Text>
                                </View>
                                <View style={styles.ticketDetailsHeadingProfileView}>
                                    <Text style={styles.ticketDetailsHeadingProfileText} dataSet={{ media: ids.ticketDetailsHeadingProfileText }}>Ticket</Text>
                                </View>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}>Quantity</Text>
                                </View>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}>Price</Text>
                                </View>
                                <View style={styles.ticketDetailsHeadingQtyPriceView}>
                                    <Text style={styles.ticketDetailsHeadingQtyPriceText} dataSet={{ media: ids.ticketDetailsHeadingQtyPriceText }}></Text>
                                </View>
                            </View>

                            <FlatList
                                style={styles.ticketTypeList}
                                data={ticketDetailsList}
                                ItemSeparatorComponent={<View style={styles.ticketSeparator} />}
                                renderItem={({ item, index }) => {
                                    return (
                                        <View style={styles.ticketTypeListItemView}>
                                            <View style={styles.ticketTypeQtyView}>
                                                <Text style={styles.ticketTypeQtyText} dataSet={{ media: ids.ticketTypeQtyText }}>{item.booking_id}</Text>
                                            </View>
                                            <View style={styles.ticketDetailsTypeView}>
                                                <Text style={styles.ticketTypeText} dataSet={{ media: ids.ticketTypeText }}>{item.ticket_title} </Text>
                                            </View>
                                            <View style={styles.ticketTypeQtyView}>
                                                <Text style={styles.ticketTypeQtyText} dataSet={{ media: ids.ticketTypeQtyText }}>{item.quantity}</Text>
                                            </View>
                                            <View style={styles.ticketTypeQtyView}>
                                                <Text style={styles.ticketTypeQtyText} dataSet={{ media: ids.ticketTypeQtyText }}>{item.ticket_price}</Text>
                                            </View>
                                            <View style={styles.ticketTypeDownloadView}>
                                                <Pressable onPress={() => Linking.openURL(item.download_pdf)} style={styles.ticketTypeDownloadButton}>
                                                    <Text style={styles.ticketTypeDownloadText} dataSet={{ media: ids.ticketTypeDownloadText }}>{"Download"}</Text>
                                                </Pressable>
                                            </View>
                                        </View>
                                    );
                                }} />

                            {/* <Pressable onPress={() => {
                                hideTicketDetailsDialog();
                                // navigation.navigate("PdfGenerateScreen", {
                                //     paramEvenetName: eventName,
                                //     paramEvenetDate: eventDate,
                                //     paramEvenetTime: eventTime,
                                //     paramEvenetOrderId: commonOrderId,
                                //     paramEvenetTotal: total,
                                //     paramEvenetTickets: ticketDetailsList,
                                // })
                                generatePDF();
                            }} style={[styles.viewTicketButtonView, { marginTop: 30 }]}>
                                <Text style={styles.viewTicketButtonText} dataSet={{ media: ids.viewTicketButtonText }}>Download Tickets</Text>
                            </Pressable> */}

                        </View>
                    </View>
                </Modal>
                {/* end of ticket details modal */}

            </View>
        </Provider>
    );
};

export default ProfileScreen;
