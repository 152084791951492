import React, { useState, useEffect } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image, } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail } from '../common/utils';
import { colors } from '../common/color';
import { useGoogleLogin } from '@react-oauth/google';
import { GUESTCHECKOUT, LOGIN, SENDOTP } from '../common/webUtils';
import { ACCESS_TOKEN, CUSTOMER_ID, EMAIL, GUEST_USER_ID, NAME, PHONE, SOCIAL_LOGIN, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import { ids, styles } from '../common/responsive';


function GuestCheckoutScreen({ navigation, route }) {

    var onlyDigitReg = /^\d+$/;
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [fullName, setFullName] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }
    // guest checkout api
    const guestCheckoutApi = async () => {
        try {

            if (fullName.trim().length == 0) {
                alert("Enter full name");
            }
            else if (phoneNo.trim().length == 0) {
                alert("Enter Mobile Number");
            }
            // else if (phoneNo.length != 10) {
            //     alert("Enter 10-digit Mobile Number");
            // }
            else if (!onlyDigitReg.test(phoneNo)) {
                alert("Mobile Number should be only digits");
            } else {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "name": fullName,
                    "phone": phoneNo
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(GUESTCHECKOUT + "\n" + raw);

                setLoading(true);
                fetch(GUESTCHECKOUT, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);

                        if (json.status == 1) {

                            saveSession(USER_ID, json.user_id);
                            saveSession(NAME, json.name);
                            saveSession(PHONE, json.phone);
                            saveSession(SOCIAL_LOGIN, json.social_login);
                            saveSession(EMAIL, json.email);
                            // saveSession(ACCESS_TOKEN, json.access_token);
                            saveSession(CUSTOMER_ID, json.customer_id);
                            
                            setLoading(false);
                            navigation.navigate("EventDetailsScreen", { paramsEventId: route.params?.paramEventUrl })
                            // window.open(route.params?.paramEventUrl);
                            // navigation.navigate("TicketSelectionScreen", {
                            //     paramTicketDetails: ticket_details,
                            //     paramEventId: event_id,
                            //     paramEventName: eventTitle,
                            //     paramEventDate: eventDate,
                            //     paramEventTime: eventTime,
                            //     paramEventLocation: eventLocation
                            // });

                        } else {
                            setLoading(false);
                            alert(json.message);
                        }

                    })
                    .catch((error) => {
                        CustomConsole("Error=>" + error);
                        setLoading(false);
                        alert("Please try again!");
                    });
            }
        } catch (e) {
            CustomConsole("Exception=>" + e + "");
            setLoading(false);
            alert("Please try again!");
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View>
                        <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                            <Text style={styles.viewTitle}>Guest Checkout</Text>

                            {/* Full Name */}
                            <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Full Name</Text>
                            <View style={styles.textInputView}>
                                <TextInput
                                    style={styles.textInput}
                                    value={fullName}
                                    onChangeText={txt => { setFullName(txt) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Full Name"
                                    dataSet={{ media: ids.textInput }}
                                />
                                <Image source={require("../assets/user.png")} style={styles.textInputIcon} />
                            </View>

                            {/* mobile number */}
                            <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Mobile Number</Text>
                            <View style={styles.textInputView}>
                                <TextInput
                                    style={styles.textInput}
                                    value={phoneNo}
                                    onChangeText={txt => { setPhoneNo(txt.replace(/[^0-9]/g, '')) }}
                                    placeholderTextColor={colors.placeHolderColor}
                                    // maxLength={10}
                                    keyboardType='numeric'
                                    inputMode='numeric'
                                    placeholder="Enter Mobile Number"
                                    dataSet={{ media: ids.textInput }}
                                />
                                <Image source={require("../assets/mobile2.png")} style={styles.textInputIcon} />
                            </View>

                            {/* continue button view */}
                            <Pressable style={styles.commonButtonView} onPress={() => guestCheckoutApi()}>
                                <Text style={styles.commonButtonText} dataSet={{ media: ids.commonButtonText }}>Continue</Text>
                            </Pressable>
                            {/* end of continue button view */}

                        </View>
                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default GuestCheckoutScreen;