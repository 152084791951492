import React, { useState, useEffect } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image, ImageBackground, Linking, } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail, } from '../common/utils';
import { colors } from '../common/color';
import { USER_ID, getSession, } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import { heightPercentageToDP, widthPercentageToDP } from 'react-native-responsive-screen';
import { CONTACTUS } from '../common/webUtils';
import { ids, styles } from '../common/responsive';
import { ADDRESS, CONTACTNO, EMAIL_ADDRESS, MAP_LINK } from '../common/string';

function ContactScreen({ navigation }) {

    var onlyDigitReg = /^\d+$/;

    const [name, setName] = useState('');
    const [errName, setErrName] = useState(false);
    const [email, setEmail] = useState('');
    const [errEmail, setErrEmail] = useState(false);
    const [errValidEmail, setErrValidEmail] = useState(false);
    const [message, setMessage] = useState('');
    const [errMessage, setErrMessage] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // contact save api
    const saveContact = () => {

        try {
            if (name.trim().length == 0) {
                // alert("Enter name");
                setErrName(true);
            }
            else if (email.trim().length == 0) {
                // alert("Enter email");
                setErrEmail(true);
            }
            else if (!validateEmail(email.trim())) {
                // alert("Invalid email");
                setErrValidEmail(true);
            }
            else if (message.trim().length == 0) {
                // alert("Enter message");
                setErrMessage(true);
            }
            else {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "name": name,
                    "email": email,
                    "message": message
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(CONTACTUS + "\n" + raw);

                setLoading(true);
                fetch(CONTACTUS, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);

                        if (json.status == 1) {
                            setLoading(false);
                            alert(json.message);
                            setName("");
                            setEmail("");
                            setMessage("");
                        } else {
                            setLoading(false);
                            alert(json.message);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });
            }
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View>

                        {/* banner view */}
                        <ImageBackground source={require("../assets/contact-banner.png")} style={styles.offerBannerBackground} imageStyle={styles.offerBannerBackgroundImage} resizeMode='stretch'>
                            <Text style={styles.offerBannerText}>Contact Us</Text>
                        </ImageBackground>
                        {/* end of banner view */}

                        <View style={styles.contactUsInformationMainView} dataSet={{ media: ids.contactUsInformationMainView }}>

                            {/* contact us information form */}
                            <View style={styles.contactUsInformationView}>
                                <Text style={styles.contactUsInformationText}>Contact Information</Text>

                                <View style={styles.contactUsInformationSubView}>
                                    <View
                                        style={styles.contactUsInformationFlexRow}>
                                        <ImageBackground source={require("../assets/circlebg2.png")}
                                            style={styles.contactUsInformationBgView}>
                                            <Image source={require("../assets/location.png")} style={styles.contactUsInformationIcon} />
                                        </ImageBackground>
                                        <Pressable style={styles.contactUsInformationPressable} onPress={() => window.location.href = MAP_LINK}>
                                            <Text style={styles.contactUsInformationPressableText}>Address: {"\n" + ADDRESS}</Text>
                                        </Pressable>
                                    </View>
                                    <View style={styles.contactUsInformationFlexRowWithMarginTop}>
                                        <ImageBackground source={require("../assets/circlebg2.png")}
                                            style={styles.contactUsInformationBgView}>
                                            <Image source={require("../assets/mobile.png")} style={styles.contactUsInformationIcon} />
                                        </ImageBackground>
                                        <View style={styles.contactUsInformationPressable}>
                                            <Text style={styles.contactUsInformationPressableText}>Call us:</Text>
                                            <Pressable onPress={() => Linking.openURL(`tel:${CONTACTNO}`)}>
                                                <Text style={styles.contactUsInformationPressableText}>{CONTACTNO}</Text>
                                            </Pressable>
                                        </View>
                                    </View>
                                    <View style={styles.contactUsInformationFlexRowWithMarginTop}>
                                        <ImageBackground source={require("../assets/circlebg2.png")}
                                            style={styles.contactUsInformationBgView}>
                                            <Image source={require("../assets/email.png")} style={styles.contactUsInformationIcon} />
                                        </ImageBackground>
                                        <View style={styles.contactUsInformationPressable}>
                                            <Text style={styles.contactUsInformationPressableText}>Email us:</Text>
                                            <Pressable onPress={() => {
                                                window.location.href = 'mailto:' + EMAIL_ADDRESS;
                                            }}>
                                                <Text style={styles.contactUsInformationPressableText}>{EMAIL_ADDRESS}</Text>
                                            </Pressable>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {/* end of contact us information form */}

                            {/* contact us form */}
                            <View style={styles.loginMainView} dataSet={{ media: ids.loginMainView }}>
                                <Text style={styles.contactUsInformationText}>Send Message to us</Text>

                                {/* name */}
                                <Text style={styles.textInputTitle}>Name</Text>
                                <View style={styles.textInputView}>
                                    <TextInput
                                        style={styles.textInput}
                                        value={name}
                                        onChangeText={txt => { setName(txt); setErrName(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Name*"
                                        dataSet={{ media: ids.textInput }}
                                    />
                                </View>
                                {errName ? <Text style={styles.errMessageText}>Enter Name</Text> : null}

                                {/* email */}
                                <Text style={styles.textInputTitle}>Email</Text>
                                <View style={styles.textInputView}>
                                    <TextInput
                                        style={styles.textInput}
                                        value={email}
                                        onChangeText={txt => { setEmail(txt); setErrEmail(false); setErrValidEmail(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Email*"
                                        dataSet={{ media: ids.textInput }}
                                    />
                                </View>
                                {errEmail ? <Text style={styles.errMessageText}>Enter Email</Text> : null}
                                {errValidEmail ? <Text style={styles.errMessageText}>Invalid email</Text> : null}

                                {/* phone */}
                                {/* <Text style={styles.textInputTitle}>Phone</Text>
                            <View style={styles.textInputView}>
                                <TextInput
                                    style={styles.textInput}
                                    value={phone}
                                    onChangeText={txt => { setPhone(txt.replace(/[^0-9]/g, '')) }}
                                    maxLength={10}
                                    keyboardType='numeric'
                                    inputMode='numeric'
                                    placeholderTextColor={colors.placeHolderColor}
                                    placeholder="Enter Phone"
                                />
                            </View> */}

                                {/* message */}
                                <Text style={styles.textInputTitle}>Message*</Text>
                                <View style={[styles.textInputView, { height: 100 }]}>
                                    <TextInput
                                        style={[styles.textInput, { height: 100, padding: 5 }]}
                                        value={message}
                                        onChangeText={txt => { setMessage(txt); setErrMessage(false); }}
                                        placeholderTextColor={colors.placeHolderColor}
                                        placeholder="Enter Message"
                                        multiline={true}
                                        dataSet={{ media: ids.textInput }}
                                    />
                                </View>
                                {errMessage ? <Text style={styles.errMessageText}>Enter Message</Text> : null}

                                {/* submit button view */}
                                <Pressable style={styles.commonButtonView} onPress={saveContact}>
                                    <Text style={styles.commonButtonText}>Submit Message</Text>
                                </Pressable>
                                {/* end of submit button view */}

                            </View>
                            {/* end of contact us form */}

                        </View>


                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default ContactScreen;