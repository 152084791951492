import React, { useState } from 'react';
import { StatusBar, View, Text, ScrollView, Image, FlatList, ImageBackground, Pressable } from 'react-native';
import { externalStyles } from '../common/style';
import { BackgroundView, CustomBorder, CustomConsole, FooterComponent, HeaderComponent, scrollToTop, } from '../common/utils';
import { useIsFocused } from '@react-navigation/native';
import { USER_ID, getSession } from '../common/LocalStorage';
import { CATEGORY } from '../common/webUtils';
import { styles } from '../common/responsive';

function OfferListScreen({ navigation }) {

    const [userId, setUserId] = useState('');
    const [offerList, setOfferList] = useState([]);
    const [loading, setLoading] = useState(false);

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // offer list api
    const offerListApi = async () => {
        try {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            CustomConsole(CATEGORY);

            setLoading(true);
            fetch(CATEGORY, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    offerList.length = 0;
                    for (let i = 0; i < json.data.length; i++) {

                        offerList.push({
                            index: i,
                            category_id: json.data[i].category_id,
                            category_name: json.data[i].category_name,
                            category_icon: json.data[i].category_icon,
                        });
                    }

                    setOfferList(offerList);

                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Offer List Api Error=>" + error);
                });
        } catch (e) {
            CustomConsole("Offer List Exception=>" + e + "");
        }
    }

    // offers item view
    const offersItem = (item, index) => {
        return (
            <Pressable
                style={externalStyles.upcomingEventItemMainView}>
                <Image source={index == 0 ? require("../assets/img1.png") : index == 1 ? require("../assets/img2.png") : require("../assets/img3.png")}
                    style={externalStyles.upcomingEventItemImage}
                />
                {/* <Pressable style={externalStyles.upcomingEventItemLikeView} onPress={() => addFavouriteEventApi(item.event_id, (item.is_favorite == "" || item.is_favorite == "0") ? "1" : "0")}>
                    <Image source={require("../assets/like.png")} style={(item.is_favorite == "" || item.is_favorite == "0") ? externalStyles.upcomingEventLikeInActive : externalStyles.upcomingEventLikeActive} />
                </Pressable> */}
                <View style={externalStyles.upcomingEventSubview}>
                    <ImageBackground source={require("../assets/datebg.png")} style={externalStyles.upcomingEventDateBackground}>
                        <Text style={externalStyles.upcomingEventDateText}>08</Text>
                        <Text style={externalStyles.upcomingEventDateText}>DEC</Text>
                    </ImageBackground>
                    <View style={externalStyles.upcomingEventSecondSubview}>
                        <Text style={externalStyles.upcomingEventTitle} numberOfLines={2}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Text>
                        <View style={externalStyles.upcomingEventLocation}>
                            <Image source={require("../assets/location.png")} style={externalStyles.upcomingEventLocationIcon} />
                            <Text style={externalStyles.upcomingEventLocationText} numberOfLines={1}>Hyaate, Vastrapur, Ahmedabad</Text>
                        </View>
                        <View style={externalStyles.upcomingEventPriceView}>
                            <Image source={require("../assets/ticket.png")} style={externalStyles.upcomingEventPriceIcon} />
                            <Text style={externalStyles.upcomingEventPriceText} numberOfLines={1}>INR 999 /Person</Text>
                        </View>
                    </View>
                </View>
            </Pressable>
        );
    }

    return (
        <View style={externalStyles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={externalStyles.offerscreenMainView}>

                        {/* banner view */}
                        <ImageBackground source={require("../assets/evenlist-banner.png")} style={externalStyles.offerBannerBackground} imageStyle={externalStyles.offerBannerBackgroundImage} resizeMode='stretch'>
                            <Text style={externalStyles.offerBannerText}>Offers</Text>
                            {CustomBorder()}
                            <Text style={externalStyles.offerBannerDescription}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
                        </ImageBackground>
                        {/* end of banner view */}

                        {/* offer list list */}
                        <FlatList style={externalStyles.offerList}
                            data={[1, 2, 3]}
                            ItemSeparatorComponent={<View style={externalStyles.offerSeparator} />}
                            renderItem={({ item, index }) => offersItem(item, index)} />
                        {/* end of offer list list */}

                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default OfferListScreen;
