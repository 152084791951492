import React, { useState, useEffect } from 'react';
import { StatusBar, View, Text, ScrollView, useWindowDimensions, ImageBackground, } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, getPlusJakartaSansRegularFont, scrollToTop, } from '../common/utils';
import { colors } from '../common/color';
import { OTHERSCREENS } from '../common/webUtils';
import { USER_ID, getSession, } from '../common/LocalStorage';
import { useIsFocused } from '@react-navigation/native';
import RenderHtml from 'react-native-render-html';
import { ids, styles } from '../common/responsive';

function CommonScreen({ navigation, route }) {

    const { width } = useWindowDimensions();

    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [image, setImage] = useState('');

    const screen_name = route.params.paramScreen;

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused, screen_name])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
            commonPageListApi();
        } catch (error) {

        }
    }

    // common events list api
    const commonPageListApi = async () => {
        try {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "screen": screen_name
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            CustomConsole(OTHERSCREENS + "\n" + raw);

            setLoading(true);
            fetch(OTHERSCREENS, requestOptions)
                .then(response => response.json())
                .then((json) => {
                    CustomConsole(json);

                    if (json.status == 1) {
                        setTitle(json.data[0].title);
                        setBody(json.data[0].body);
                        setImage(json.data[0].image);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    CustomConsole("Common page Api Error=>" + error);
                });
        } catch (e) {
            setLoading(false);
            CustomConsole("Common page Exception=>" + e + "");
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* main view */}
                    <View style={{}}>

                        {/* banner view */}
                        <ImageBackground source={require("../assets/about-banner1.png")} style={styles.offerBannerBackground} imageStyle={styles.offerBannerBackgroundImage} resizeMode='stretch'>
                            <Text style={styles.offerBannerText}>{title}</Text>
                        </ImageBackground>
                        {/* end of banner view */}

                        <RenderHtml baseStyle={styles.commonRenderHtmlText}
                            contentWidth={width}
                            source={{ html: body }}
                            tagsStyles={tagsStyles}
                            defaultTextProps={{ selectable: true }}
                            systemFonts={[getPlusJakartaSansRegularFont()]}
                        />
                    </View>
                    {/* end of main view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default CommonScreen;

const tagsStyles = {
    p: {
        color: colors.white,
        fontFamily: getPlusJakartaSansRegularFont()
    },
    div: {
        width: '500px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        // textOverflow: 'ellipsis'
    }
}