import { Dimensions, } from 'react-native';
import { colors } from './color';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';
// import { getBebasNeueRegularFont, getPlusJakartaSansBoldFont, getPlusJakartaSansRegularFont, getPlusJakartaSansSemiBoldFont } from './utils';
import StyleSheet from 'react-native-media-query';


// custom fonts
export const getBebasNeueRegularFont = () => {
    return "BebasNeue-Regular"
}
export const getPlusJakartaSansBoldFont = () => {
    return "PlusJakartaSans-Bold"
}
export const getPlusJakartaSansExtraBoldFont = () => {
    return "PlusJakartaSans-ExtraBold"
}
export const getPlusJakartaSansExtraLightFont = () => {
    return "PlusJakartaSans-ExtraLight"
}
export const getPlusJakartaSansLightFont = () => {
    return "PlusJakartaSans-Light"
}
export const getPlusJakartaSansMediumFont = () => {
    return "PlusJakartaSans-Medium"
}
export const getPlusJakartaSansRegularFont = () => {
    return "PlusJakartaSans-Regular"
}
export const getPlusJakartaSansSemiBoldFont = () => {
    return "PlusJakartaSans-SemiBold"
}
export const getPlusJakartaSansVariableFont_wghtFont = () => {
    return "PlusJakartaSans-VariableFont_wght"
}

const { ids, styles } = StyleSheet.create({

    //common
    container: {
        backgroundColor: "black", overflow: "hidden",
    },
    textInputTitle: {
        fontSize: 12
    },
    scrollContainer: {
        backgroundColor: "transparent",
        //  marginHorizontal: wp("16%"), maxWidth: '1320px', marginRight: 'auto', marginLeft: 'auto',
        '@media (max-width: 1399px)': {
            marginRight: 0,
            marginLeft: 0,
            // width: '1170',
        },
        '@media (max-width: 1199px)': {
            marginRight: 0,
            marginLeft: 0,
            // width: '960',
        },
        '@media (max-width: 991px)': {
            marginRight: 0,
            marginLeft: 0,
            // width: '720px',
        },
        '@media (max-width: 767px)': {
            marginRight: 0,
            marginLeft: 0,
        },
        '@media (max-width: 639px)': {
            marginRight: 0,
            marginLeft: 0,
        },
        '@media (max-width: 479px)': {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    errMessageText: {
        fontSize: hp("2%"),
        color: colors.red,
        fontWeight: "600",
        marginLeft: 10
    },

    inactive_tab_circle: {
        width: 10,
        height: 10,
        marginHorizontal: 5,
        backgroundColor: "#724d4f",
        borderRadius: 10 / 2
    },
    active_tab_circle: {
        width: 10, //30
        height: 10,
        marginHorizontal: 5,
        backgroundColor: colors.buttonColor,
        borderRadius: 10 / 2
    },

    // custom border
    customBorder: {
        borderWidth: 2, borderColor: colors.buttonColor, width: 50, marginTop: 5
    },

    // background view for all screen
    backgroundMainView: {
        overflow: "hidden", position: "absolute", top: 0, right: 0, left: 0, backgroundColor: colors.black, overflow: "hidden",

        '@media (max-width: 991px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 767px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 639px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 479px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
    },
    backgroundImage1: {
        height: 500, width: 500, position: "absolute", left: -150, top: -100
    },
    backgroundMultipleImageList: {
        alignSelf: "center"
    },
    backgroundImage2: {
        height: 500, width: 500, marginTop: 200
    },

    // header
    headerMainView: {
        backgroundColor: "transparent", flexDirection: "row", alignItems: "center", paddingVertical: 50, justifyContent: "space-between", paddingRight: '15%', paddingLeft: '15%',
        paddingHorizontal: wp("3%"),
        // paddingHorizontal:100,
        '@media (max-width: 1399px)': {
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
        '@media (max-width: 1199px)': {
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
        '@media (max-width: 991px)': {
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
        '@media (max-width: 767px)': {
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
        '@media (max-width: 639px)': {
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
        '@media (max-width: 479px)': {
            paddingTop: 15,
            paddingBottom: 15,
            paddingLeft: 15,
            paddingRight: 15,
        },
    },
    headerSubView: {
        flexDirection: "row", alignItems: "center"
    },
    headerMenuText: {
        fontSize: 14, color: colors.white, marginRight: 20,
    },
    headerLogo: {
        width: '250px', height: '78px', resizeMode: "contain",
        '@media (max-width: 1399px)': {
            width: '250px',
            height: '78px',
            marginTop: 15,
            marginBottom: 15,
        },
        '@media (max-width: 1199px)': {
            width: '250px',
            height: '78px',
            marginTop: 15,
            marginBottom: 15,
        },
        '@media (max-width: 991px)': {
            width: '150px',
            height: '56px',
            marginTop: 1,
            marginBottom: 1,
        },
        '@media (max-width: 767px)': {
            width: '150px',
            height: '56px',
        },
        '@media (max-width: 639px)': {
            width: '150px',
            height: '56px',
        },
        '@media (max-width: 479px)': {
            width: '150px',
            height: '56px',
        },
    },
    headerMenuIconView: {
        display: 'none',
        '@media (max-width: 1199px)': {
            display: 'none'
        },
        '@media (max-width: 991px)': {
            display: 'flex'
        },
        '@media (max-width: 767px)': {
            display: 'flex'
        },
        '@media (max-width: 639px)': {
            display: 'flex'
        },
        '@media (max-width: 479px)': {
            display: 'flex'
        },
    },
    headerMenuIcon: {
        width: wp("5%"), height: hp("3%"), resizeMode: "stretch"
    },
    headerPressableView: {
        flexDirection: "row", alignItems: "center",
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
            display: 'none'
        },
        '@media (max-width: 767px)': {
            display: 'none'
        },
        '@media (max-width: 639px)': {
            display: 'none'
        },
        '@media (max-width: 479px)': {
            display: 'none'
        },
    },
    headerPressableSubView: {
        marginHorizontal: 15
    },
    headerPressableText: {
        color: colors.buttonColor, fontSize: 20, fontFamily: getPlusJakartaSansMediumFont()
    },
    headerCountrySelection: {
        flexDirection: "row", alignItems: "center", marginRight: 30,
    },
    headerCountrySelectionIcon: {
        height: 30, width: 50, resizeMode: "contain"
    },
    headerCountrySelectionArrow: {
        width: 20, height: 20, resizeMode: "contain", tintColor: colors.white
    },
    headerModalMainView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: '#320002'
    },
    headerModalSubView: {
        // alignItems: 'center',
        justifyContent: "center",
        height: hp("100%"),
        margin: 'auto',
        padding: 30,
        width: wp("100%"),
        paddingHorizontal: wp("7%"),
        paddingVertical: hp("6%")
    },
    headerModalCloseButtonView: {
        paddingVertical: 10, paddingHorizontal: 15, alignSelf: "flex-end", top: hp("-20%"), paddingRight: wp("13%")
    },
    headerModalCloseButtonImage: {
        width: wp("2.7%"), height: wp("2.7%"), resizeMode: "contain", tintColor: colors.white
    },
    headerModalLinksView: {
        flexDirection: "row", marginTop: -60,
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    headerModalLinkText: {
        fontSize: 22, color: colors.white, paddingVertical: 8, fontFamily: getPlusJakartaSansSemiBoldFont()
    },
    headerModalLinkSecondView: {
        marginLeft: wp("22%"),
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            marginLeft: '0',
        },
        '@media (max-width: 479px)': {
            marginLeft: '0',
        },
    },
    headerCountryModalMainView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'black',
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {

        },
    },
    headerCountryModalSubView: {
        justifyContent: "center", margin: 'auto', padding: 30, borderColor: '#999', borderRadius: '8px', border: '1px solid #999',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            padding: 15,
        },
    },
    headerCountryModalCloseView: {
        paddingVertical: 10, paddingHorizontal: 15, alignSelf: "flex-end",
    },
    headerCountryModalCloseImage: {
        width: 30, height: 30, resizeMode: "contain", tintColor: colors.white
    },
    headerCountrySeparator: {
        marginVertical: 5, borderWidth: 0.1, borderColor: colors.borderColor
    },
    headerCountryListView: {
        flexDirection: "row", alignItems: "center", marginRight: 20,
    },
    headerCountryListIcon: {
        height: 30, width: 50, resizeMode: "contain"
    },
    headerCountryListText: {
        color: colors.white,
        fontSize: 20,
        fontFamily: getPlusJakartaSansSemiBoldFont()
    },

    // login page
    viewTitle: {
        fontSize: 25,
        marginBottom: 15,
        color: colors.white,
        textTransform: 'uppercase',
        fontWeight: "bold",
        textAlign: 'center'
    },
    loginMainView: {
        backgroundColor: colors.loginBackground,
        borderColor: colors.loginViewBorder,
        borderWidth: 1,
        marginVertical: "5%",
        marginHorizontal: "auto",
        borderRadius: 30,
        paddingHorizontal: "20px",
        paddingVertical: "20px",
        justifyContent: "center",
        width: '400px',
        '@media (max-width: 1399px)': {

        },
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            width: '300px'
        },
    },
    textInputTitle: {
        fontSize: '24px',
        color: '#a7a7a7',
        fontWeight: "600",
        marginTop: 15,
        fontFamily: getPlusJakartaSansRegularFont(),
        '@media (max-width: 1399px)': {

        },
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: '18px',
        },
        '@media (max-width: 479px)': {
            fontSize: '18px',
        },
    },
    textInputView: {
        height: hp("7%"),
        backgroundColor: colors.textInputBackground,
        borderWidth: 1,
        borderColor: colors.textInputBorder,
        borderRadius: 10,
        marginTop: 5,
        marginBottom: 10,
        flexDirection: "row",
        alignItems: "center",
        paddingHorizontal: 15
    },
    textInput: {
        height: 47,
        flex: 1,
        color: colors.white,
        fontSize: '18px',
        fontWeight: "500",
        fontFamily: getPlusJakartaSansRegularFont(),
        outline: "none",
        '-webkit-box-shadow': '0 0 0 30px rgb(0 0 0 / 0%) inset',
        '-webkit-text-fill-color': 'white',

        // color: 'rgb(255,255,255,1) !important', 
        // 'input:-webkit-autofill': {
        //     backgroundColor: 'black', // Adjust background color for autofill
        //     color: 'white', // Adjust text color for autofill
        // },
        '@media (max-width: 1399px)': {
            // 'input:-webkit-autofill': {
            //     backgroundColor: 'black', // Adjust background color for autofill
            //     color: 'black', // Adjust text color for autofill
            // },
        },
        '@media (max-width: 1199px)': {
            // 'input:-webkit-autofill': {
            //     backgroundColor: 'black', // Adjust background color for autofill
            //     color: 'black', // Adjust text color for autofill
            // },
        },
        '@media (max-width: 991px)': {
            // 'input:-webkit-autofill': {
            //     backgroundColor: 'black', // Adjust background color for autofill
            //     color: 'black', // Adjust text color for autofill
            // },
        },
        '@media (max-width: 767px)': {
            // 'input:-webkit-autofill': {
            //     backgroundColor: 'black', // Adjust background color for autofill
            //     color: 'black', // Adjust text color for autofill
            // },
        },
        '@media (max-width: 639px)': {
            fontSize: '16px',
            // 'input:-webkit-autofill': {
            //     '-webkit-box-shadow': '0 0 0 30px rgb(0, 0, 0 / 0%) inset', /* Change the background color */
            //     backgroundColor: 'black', // Adjust background color for autofill
            //     color: '#fffff', // Adjust text color for autofill
            // },
            // 'input:-webkit-autofill': {
            //     backgroundColor: 'red !important', /* Adjust background color for autofill */
            //     color: 'rgb(255,255,255,1) !important', /* Adjust text color for autofill */
            //     '-webkit-box-shadow': '0 0 0 30px rgba(0, 0, 0, 0.5) inset !important', /* Adjust box shadow color for autofill */
            //     'box-shadow': '0 0 0 30px rgba(0, 0, 0, 0.5) inset !important' /* Adjust box shadow color for autofill */
            //   }
        },
        '@media (max-width: 479px)': {
            fontSize: '16px',
            // 'input:-webkit-autofill': {
            //     backgroundColor: 'black', // Adjust background color for autofill
            //     color: 'black', // Adjust text color for autofill
            // },
        },
    },
    textInputIcon: {
        height: hp("4%"),
        width: hp("4%"),
        resizeMode: "contain",
        tintColor: colors.icon,
    },
    termConditionsView: {
        flexDirection: "row",
        alignItems: "center"
    },
    termConditionsText: {
        fontSize: 20,
        marginLeft: 10,
        color: colors.title,
        fontWeight: "600",
        fontFamily: getPlusJakartaSansBoldFont(),
        '@media (max-width: 1399px)': {

        },
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: '16px',
        },
        '@media (max-width: 479px)': {
            fontSize: '16px',
        },
    },
    termConditionsPressable: {
        flex: 1
    },

    commonButtonView: {
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22
    },
    commonButtonText: {
        color: colors.white,
        fontSize: 20,
        textAlign: 'center',
        fontFamily: getPlusJakartaSansBoldFont(),
        '@media (max-width: 1399px)': {

        },
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    bottomContainer: {
        flexDirection: "row",
        alignSelf: "center",
        marginTop: 30,
    },
    colorBlackText: {
        fontWeight: "400",
        fontSize: 18,
        lineHeight: 18,
        color: colors.title,
        fontFamily: getPlusJakartaSansBoldFont()
    },
    createAccountText: {
        fontWeight: "400",
        fontSize: 14,
        lineHeight: 18,
        color: colors.white,
        textDecorationLine: "underline",
        fontFamily: getPlusJakartaSansBoldFont()
    },
    forgotPasswordContainer: {
        alignSelf: "flex-end",
        marginTop: 3
    },
    forgotPasswordText: {
        color: colors.title,
        fontWeight: "400",
        fontSize: 15,
        lineHeight: 14,
        fontFamily: getPlusJakartaSansBoldFont()
    },
    googleButtonView: {
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#252525",
        marginTop: 22
    },
    googleIcon: {
        width: 30,
        height: 30,
        marginRight: 10,
        resizeMode: "contain"
    },

    // footer 
    footerMainView: {
        paddingTop: 20, marginTop: 50
    },
    footerSubViewCopyright: {
        backgroundColor: colors.buttonColor, paddingVertical: 10, marginTop: 40, fontWeight: '400', paddingTop: 20, paddingBottom: 20,
    },
    copyRightText: {
        fontSize: 16, color: colors.white, textAlign: "center", fontFamily: getPlusJakartaSansSemiBoldFont(), fontWeight: '400',
    },
    alakmalakText: {
        fontSize: 16, color: colors.white, textAlign: "center", marginTop: 10, fontFamily: getPlusJakartaSansSemiBoldFont(), fontWeight: '400'
    },
    footerSubView1: {
        alignItems: "center"
    },
    backImg: {
        height: 350, width: 350, position: "absolute", alignSelf: "center"
    },
    footerLogo: {
        width: 250, height: 80, resizeMode: "stretch",
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            width: '150px',
            height: '47px',
        },
    },
    linkView1: {
        flexDirection: "row", alignItems: "center", marginTop: 40,
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            display: "block",
        },
    },
    footerLine: {
        width: 250, height: 2, resizeMode: "stretch", marginTop: 40
    },
    linkViewTextWithRightBorder: {
        fontSize: 20, color: colors.white, textAlign: "center", paddingHorizontal: 15, alignSelf: "center", borderRightWidth: 1, borderRightColor: colors.white, fontFamily: getPlusJakartaSansSemiBoldFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            borderStyle: 'none',
            fontSize: 16,
            marginBottom: 10,
        },
    },
    linkViewTextWithoutRightBorder: {
        fontSize: 20, color: colors.white, textAlign: "center", paddingHorizontal: 15, alignSelf: "center",
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            borderStyle: 'none',
            fontSize: 16,
            marginBottom: 10,
        },
    },
    privacyTextWithRightBorder: {
        fontSize: 15, color: colors.title, textAlign: "center", paddingHorizontal: 10, alignSelf: "center", borderRightWidth: 1, borderRightColor: colors.white, fontFamily: getPlusJakartaSansSemiBoldFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            borderStyle: 'none',
            marginBottom: 10,
        },
    },
    privacyTextWithoutRightBorder: {
        fontSize: 15, color: colors.title, textAlign: "center", paddingHorizontal: 15, alignSelf: "center",
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            borderStyle: 'none',
            marginBottom: 10,
        },
    },

    // Home screen
    pickerStyle: {
        color: colors.white,
        paddingHorizontal: 10,
        backgroundColor: colors.black,
        height: 45,
        borderWidth: 1,
        borderRadius: 10,
        borderColor: '#9A9A9A',
        width: wp("10%"),
        marginRight: 15,
        fontFamily: getPlusJakartaSansSemiBoldFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            width: 80,
            marginRight: 0,
        },
    },
    bannerImage: {
        height: hp("50%"), //550
        width: Dimensions.get("window").width * 0.61,
        resizeMode: "stretch",
        borderRadius: 50,
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
        },
        '@media (max-width: 639px)': {
        },
        '@media (max-width: 479px)': {
            width: '100%',
            height: 'auto'
        },
    },
    bannerSwiperImage: {
        resize: "block", borderRadius: 30, height: 'auto',
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
        },
        '@media (max-width: 639px)': {
        },
        '@media (max-width: 479px)': {
        },
    },
    bannerView: {
        // flexDirection: "row", alignItems: "center",
        marginTop: 15, marginBottom: 15, paddingRight: '15%', paddingLeft: '15%',
        '@media (max-width: 1399px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 1199px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 991px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 767px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 639px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 479px)': {
            paddingRight: 15,
            paddingLeft: 15,
            height: 'auto'
        },
    },
    bannerListView: {
        marginHorizontal: wp("3%"),
    },
    bannerSeparator: {
        margin: 25
    },
    categoryItemBackgroundImage: {
        // height: Dimensions.get("window").width / 8, //270
        // width: Dimensions.get('window').width / 8, //270
        alignItems: "center",
        justifyContent: "center",
        margin: 15,
        width: '250px',
        height: '250px',
        '@media (max-width: 1399px)': {
            width: '250px',
            height: '250px',
        },
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            // width: '250px',
            // height: '250px',
            // margin: 1,
        },
    },
    categoryItemIcon: {
        width: '100px',
        height: '100px',
        resizeMode: "contain",
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            // width: '72px',
            // height: '72px'
        },
    },
    categoryItemText: {
        fontSize: '32px',
        color: colors.white,
        marginTop: 15,
        width: '150px',
        textAlign: "center",
        textTransform: "uppercase",
        fontFamily: getPlusJakartaSansSemiBoldFont()
    },
    categoryView: {
        alignItems: "center", marginTop: 30, justifyContent: "center",
        '@media (max-width: 991px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 767px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 639px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 479px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
    },
    categoryListView: {
        flexDirection: 'row', flexWrap: 'wrap', alignContent: "center", alignItems: "center", justifyContent: "center", paddingRight: '15%', paddingLeft: '15%',
        '@media (max-width: 1399px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
        '@media (max-width: 1199px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
        '@media (max-width: 991px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
        '@media (max-width: 767px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
        '@media (max-width: 639px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
        '@media (max-width: 479px)': {
            paddingRight: 0,
            paddingLeft: 0,
        },
    },
    ourProductsText: {
        fontSize: 60, color: colors.white, textAlign: "center", textTransform: "uppercase", fontFamily: getBebasNeueRegularFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            fontSize: 40,
        },
    },
    ourProductsDescription: {
        fontSize: 22, color: colors.title, textAlign: "center", marginTop: "1%", paddingHorizontal: "15%", alignSelf: "center", fontFamily: getPlusJakartaSansRegularFont(), lineHeight: '26px',
        '@media (max-width: 1199px)': {
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: 16,
        },
        '@media (max-width: 991px)': {
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: 16,
        },
        '@media (max-width: 767px)': {
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: 16,
        },
        '@media (max-width: 639px)': {
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            paddingRight: 0,
            paddingLeft: 0,
            fontSize: 16,
        },
    },
    upcomingEventListView: {
        marginTop: 30, flexDirection: 'row', flexWrap: 'wrap', alignContent: "center", alignItems: "center", justifyContent: "center", width: '100%',
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            marginLeft: '15px',
            marginRight: '15px',
        },
    },
    upcomingEventItemMainView: {
        width: Dimensions.get("window").width / 4 + 30,
        marginHorizontal: 7,
        marginBottom: 15,
        alignSelf: "center",
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            width: '100%',
            marginLeft: 0,
            marginRight: 0,
        },
    },
    upcomingEventItemImage: {
        // height: 300,
        // width: Dimensions.get("window").width / 4 + 30,
        backgroundColor: colors.eventBackground,
        resizeMode: "stretch",
        borderTopRightRadius: 20,
        borderTopLeftRadius: 20,
        width: '100%',
        margin: '40px 1% 0 0',
        height: '280px',
        '@media (max-width: 1199px)': {
            width: '100%'
        },
        '@media (max-width: 991px)': {
            width: '100%'
        },
        '@media (max-width: 767px)': {
            width: '100%',
            height: '200px',
        },
        '@media (max-width: 639px)': {
            width: '100%',
            height: '200px',
        },
        '@media (max-width: 479px)': {
            width: '100%',
            height: '200px',
        },
    },
    upcomingEventItemLikeView: {
        position: "absolute", top: 30, right: 30,
    },
    upcomingEventItemWithoutLikeImage: {
        width: 50,
        height: 50,
        resizeMode: "contain",
        tintColor: colors.white
    },
    upcomingEventItemLikeImage: {

    },
    upcomingEventSubview: {
        flexDirection: "row",
        paddingVertical: 30,
        paddingHorizontal: 25,
        backgroundColor: colors.eventBackground,
        borderBottomLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    upcomingEventDateBackground: {
        width: 80, height: 80, justifyContent: "center", alignItems: "center"
    },
    upcomingEventDateText: {
        fontSize: 20, color: colors.white, textAlign: "center", marginTop: "1%", paddingHorizontal: "15%", alignSelf: "center", fontFamily: getPlusJakartaSansSemiBoldFont()
    },
    upcomingEventSecondSubview: {
        flex: 1, marginLeft: 15
    },
    upcomingEventTitle: {
        fontSize: 20, color: colors.white, fontFamily: getPlusJakartaSansSemiBoldFont(), minHeight: '50px'
    },
    upcomingEventLocation: {
        flexDirection: "row", alignItems: "center", marginTop: 10,
    },
    upcomingEventLocationIcon: {
        height: 15, width: 15, resizeMode: "contain"
    },
    upcomingEventLocationText: {
        fontSize: 20, color: colors.eventLocation, marginLeft: 5, fontFamily: getPlusJakartaSansSemiBoldFont()
    },
    upcomingEventPriceView: {
        flexDirection: "row", flex: 1, alignItems: "center", marginTop: 10, borderRadius: 0, padding: 10, alignSelf: "flex-start"
    },
    upcomingEventPriceBgImage: {
        resizeMode: "stretch",
    },
    upcomingEventPriceIcon: {
        height: 15, width: 15, resizeMode: "contain"
    },
    upcomingEventPriceText: {
        fontSize: 15, color: colors.eventLocation, marginLeft: 5, fontFamily: getPlusJakartaSansSemiBoldFont(), flex: 1
    },
    upcomingEventView: {
        alignItems: "center", marginTop: 30,
        '@media (max-width: 991px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 767px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 639px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 479px)': {
            paddingRight: '15px',
            paddingLeft: '15px',
            width: '100%'
        },
    },
    upcomingEventSeparator: {
        marginTop: 50
    },
    upcomingEventTypeTabView: {
        flexDirection: "row", alignItems: 'center', marginVertical: 30
    },
    upcomingEventTypeTabText: {
        fontSize: 15, color: colors.white, textAlign: "center", paddingHorizontal: "15%", alignSelf: "center", textTransform: "uppercase", fontFamily: getPlusJakartaSansBoldFont()
    },
    upcomingEventTypeActiveTab: {
        backgroundColor: "red",
        padding: 15,
        borderWidth: 0,
        borderColor: "transparent",
        borderRadius: 15,
        // width: 100,
        marginRight: 15
    },
    upcomingEventTypeInActiveTab: {
        backgroundColor: "transparent",
        padding: 15,
        borderWidth: 2,
        borderColor: "#4d4d4d",
        borderRadius: 15,
        // width: 100,
        marginRight: 15,
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            padding: 5,
            marginRight: 10,
            width: 80,
        },
    },
    upcomingEventLikeActive: {
        width: 50,
        height: 50,
        resizeMode: "contain",
        tintColor: colors.buttonColor
    },
    upcomingEventLikeInActive: {
        width: 50,
        height: 50,
        resizeMode: "contain",
        tintColor: colors.white
    },

    // offerlist
    offerscreenMainView: {
        alignItems: "center", marginTop: 30
    },
    offerBannerBackground: {
        height: hp("55%"), alignItems: "center", marginHorizontal: "1%", justifyContent: "center",
    },
    offerBannerBackgroundImage: {
        borderRadius: 20
    },
    offerBannerText: {
        fontSize: 60, color: colors.white, textAlign: "center", textTransform: "uppercase", fontFamily: getBebasNeueRegularFont(),
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {
            fontSize: 30,
        },
        '@media (max-width: 639px)': {
            fontSize: 30,
        },
        '@media (max-width: 479px)': {
            fontSize: 30,
        },
    },
    offerBannerDescription: {
        fontSize: 22, color: colors.title, textAlign: "center", marginTop: "1%", paddingHorizontal: "15%", alignSelf: "center", fontFamily: getPlusJakartaSansRegularFont(),
        '@media (max-width: 1199px)': {
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 16,
        },
        '@media (max-width: 991px)': {
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 16,
        },
        '@media (max-width: 767px)': {
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 16,
        },
        '@media (max-width: 639px)': {
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            paddingRight: 15,
            paddingLeft: 15,
            fontSize: 16,
        },
    },
    offerList: {
        marginTop: 30
    },
    offerSeparator: {
        marginTop: 50
    },
    ticketSeparator: {
        marginVertical: 10, borderWidth: 1, borderColor: colors.borderColor
    },

    // profile screen
    profileMainView: {
        paddingVertical: "3%", paddingHorizontal: "15%",
        '@media (max-width: 1399px)': {
            padding: "15px",
        },
        '@media (max-width: 1199px)': {
            padding: "15px",
        },
        '@media (max-width: 991px)': {
            padding: "15px",
        },
        '@media (max-width: 767px)': {
            padding: "15px",
        },
        '@media (max-width: 639px)': {
            padding: "15px",
        },
        '@media (max-width: 479px)': {
            padding: "15px",
        },
    },
    profileTabView: {
        flexDirection: "row", alignItems: "center", borderBottomWidth: 1, borderColor: colors.white
    },
    profileTabText: {
        fontSize: 20,
        color: colors.white,
        textAlign: 'center',
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
        },
        '@media (max-width: 639px)': {
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    profileActiveTab: {
        borderBottomWidth: 2,
        paddingVertical: 10,
        marginRight: 20,
        borderBottomColor: colors.white
    },
    profileInActiveTab: {
        borderBottomWidth: 0,
        paddingVertical: 10,
        marginRight: 20,
        borderBottomColor: colors.white
    },
    profileSubView: {
        marginTop: 50,
        backgroundColor: colors.loginBackground,
        borderColor: colors.loginViewBorder,
        borderWidth: 1,
        borderRadius: 30,
        paddingHorizontal: "5%",
        paddingVertical: "5%",
        justifyContent: "center"
    },
    personalDetailsText: {
        fontSize: 20,
        marginBottom: 15,
        color: colors.white,
        textTransform: 'uppercase',
        fontWeight: "bold",
        textAlign: 'left'
    },
    personalDetailsView: {
        flexDirection: "row", alignItems: "center",
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    personalDetailsFirstSubView: {
        flex: 1, marginRight: 15,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            display: "block",
            flex: 'inherit',
            marginRight: 0,
            width: '100%',
            textAlign: 'left',
        },
        '@media (max-width: 639px)': {
            display: "block",
            flex: 'inherit',
            marginRight: 0,
            width: '100%',
            textAlign: 'left',
        },
        '@media (max-width: 479px)': {
            display: "block",
            flex: 'inherit',
            marginRight: 0,
            width: '100%',
            textAlign: 'left',
        },
    },
    personalDetailsSecondSubView: {
        flex: 1,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            display: "block",
            flex: 'inherit',
            marginRight: 0,
            width: '100%',
            textAlign: 'left',
        },
        '@media (max-width: 639px)': {
            display: "block",
            flex: 'inherit',
            marginRight: 0,
            width: '100%',
            textAlign: 'left',
        },
        '@media (max-width: 479px)': {
            display: "block",
            flex: 'inherit',
            marginRight: 0,
            width: '100%',
            textAlign: 'left',
        },
    },
    editProfileButton: {
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        alignSelf: "center", paddingHorizontal: 30
    },
    editProfileButtonText: {
        color: colors.white,
        fontSize: 20,
        fontWeight: "600",
        textAlign: 'center',
    },
    logoutModalMainView: {
        marginTop: Dimensions.get("window").height * 0.8, width: 300, backgroundColor: colors.white, alignSelf: "center"
    },
    logoutModalTitle: {
        color: colors.buttonColor, alignSelf: "center"
    },
    logoutModalSubTitle: {
        color: colors.black, alignSelf: "center"
    },
    logoutModalButtonView: {
        flexDirection: "row", alignSelf: "center"
    },
    logoutModalButton: {
        backgroundColor: colors.buttonColor, width: 100, borderRadius: 10
    },
    ticketItemMainView: {
        flexDirection: "row", alignItems: "center"
    },
    ticketItemDetailsView: {
        flex: 1.5, paddingLeft: 10,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            paddingLeft: 0,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            paddingLeft: 0,
        },
    },
    ticketItemDetailsTitle: {
        fontSize: 20,
        color: colors.white,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketItemDetailsText: {
        fontSize: 15,
        color: colors.title,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 13,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 13,
        },
    },
    ticketItemDetailsOrderId: {
        fontSize: 15,
        color: colors.white,
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 13,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 13,
        },
    },
    ticketItemDetailsFlexOne: {
        flex: 1
    },
    ticketItemDetailsFlexPointFive: {
        flex: 0.5
    },
    ticketItemDetailsQtyPrice: {
        fontSize: 20,
        color: colors.white,
        textAlign: 'center',
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketItemDetailsPressableText: {
        fontSize: 20,
        color: colors.white,
        textAlign: 'center',
        textDecorationLine: "underline",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {
            fontSize: 16,
        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketDetailsHeadingView: {
        flexDirection: "row", alignItems: "center", backgroundColor: "#724d4f", marginTop: 20, paddingVertical: 15
    },
    ticketDetailsModalView: {
        flexDirection: "row"
    },
    ticketDetailsModalViewWithMarginTop: {
        flexDirection: "row", marginTop: 20
    },
    ticketDetailsHeadingProfileView: {
        flex: 1.5, paddingLeft: 10
    },
    ticketDetailsHeadingProfileText: {
        fontSize: 18,
        color: colors.white,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
            fontSize: 16,
        },
        '@media (max-width: 767px)': {
            fontSize: 16,
        },
        '@media (max-width: 639px)': {
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketDetailsHeadingQtyPriceView: {
        flex: 1
    },
    ticketDetailsHeadingDetailsView: {
        flex: 0.5
    },
    ticketDetailsHeadingQtyPriceText: {
        fontSize: 18,
        color: colors.white,
        textAlign: 'center',
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
            fontSize: 16,
        },
        '@media (max-width: 767px)': {
            fontSize: 16,
        },
        '@media (max-width: 639px)': {
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketDetailsList: {
        marginTop: 10, marginBottom: 200
    },
    emptyStateView: {
        height: 500, justifyContent: "center"
    },
    emptyStateText: {
        textAlign: 'center', fontSize: 20, color: colors.white, textTransform: "uppercase", fontFamily: getPlusJakartaSansSemiBoldFont()
    },
    ticketTypeList: {
        marginTop: 10
    },
    ticketTypeListItemView: {
        flexDirection: "row", alignItems: "center"
    },
    ticketDetailsTypeView: {
        flex: 1.5,
        paddingLeft: 10,
    },
    ticketTypeText: {
        fontSize: 20,
        paddingLeft: 10,
        color: colors.white,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketTypeQtyView: {
        flex: 1, justifyContent: 'center',
    },
    ticketTypeQtyText: {
        fontSize: 20,
        color: colors.white,
        textAlign: 'center',
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    ticketTypeDownloadView: {
        flex: 1, justifyContent: 'center',
    },
    ticketTypeDownloadButton: {
        backgroundColor: colors.buttonColor, paddingTop: 5, paddingBottom: 5, borderRadius: 5
    },
    ticketTypeDownloadText: {
        fontSize: 15,
        color: colors.white,
        textAlign: 'center',
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: 13,
        },
        '@media (max-width: 479px)': {
            fontSize: 13,
        },
    },

    // ticket confirm
    ticketConfirmMainView: {
        paddingHorizontal: "15%",
        '@media (max-width: 1399px)': {

        },
        '@media (max-width: 1199px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 991px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 767px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 639px)': {
            paddingRight: 15,
            paddingLeft: 15,
        },
        '@media (max-width: 479px)': {
            paddingRight: '15px',
            paddingLeft: '15px',
        },
    },
    ticketSummeryText: {
        fontSize: 25, color: colors.white, fontWeight: "bold",
        '@media (max-width: 767px)': {
            fontSize: 20,
        },
        '@media (max-width: 639px)': {
            fontSize: 20,
        },
        '@media (max-width: 479px)': {
            fontSize: 20,
        },
    },
    ticketPaymentText: {
        fontSize: 25, color: colors.white, fontWeight: "bold", marginBottom: 20,
        '@media (max-width: 767px)': {
            fontSize: 20,
        },
        '@media (max-width: 639px)': {
            fontSize: 20,
        },
        '@media (max-width: 479px)': {
            fontSize: 20,
        },
    },
    ticketSummeryView: {
        backgroundColor: colors.ticketBackground,
        // borderColor: colors.loginViewBorder,
        // borderWidth: 1,
        marginTop: "3%",
        // marginBottom: "3%",
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
        // paddingHorizontal: "5%",
        paddingVertical: "3%",
        justifyContent: "center"
    },
    ticketEventTitle: {
        fontSize: 20, color: colors.white, fontWeight: "600", paddingHorizontal: "5%",
        '@media (max-width: 767px)': {
            fontSize: 18,
        },
        '@media (max-width: 639px)': {
            fontSize: 18,
        },
        '@media (max-width: 479px)': {
            fontSize: 18,
        },
    },
    ticketUserDetails: {
        flexDirection: "row", marginTop: 25, paddingBottom: 20, paddingHorizontal: "5%",
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column",
        },
    },
    ticketFlexOneView: {
        flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center",
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            justifyContent: "left"
        },
        '@media (max-width: 639px)': {
            justifyContent: "left"
        },
        '@media (max-width: 479px)': {
            justifyContent: "left"
        },
    },
    ticketFlexTwoView: {
        flex: 1, flexDirection: "row", alignItems: "center",
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            justifyContent: "left"
        },
        '@media (max-width: 639px)': {
            justifyContent: "left"
        },
        '@media (max-width: 479px)': {
            justifyContent: "left"
        },
    },
    ticketFlexSecondView: {
        flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "center", borderLeftWidth: 1, borderLeftColor: colors.borderColor,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            justifyContent: "left"
        },
        '@media (max-width: 639px)': {
            justifyContent: "left"
        },
        '@media (max-width: 479px)': {
            justifyContent: "left"
        },
    },
    ticketDetails: {
        flexDirection: "row", alignItems: "center", marginTop: 25,
    },
    ticketSeperatorView: {
        flexDirection: "row", alignItems: "center"
    },
    ticketDotImage1: {
        height: 30, width: 15, resizeMode: "stretch", transform: [{ rotate: '180deg' }]
    },
    ticketDotImage2: {
        height: 30, width: 15, resizeMode: "stretch"
    },
    ticketLines: {
        borderBottomWidth: 3, borderBottomColor: "#030303", borderStyle: "dashed", flex: 1
    },
    ticketTypeListView: {
        flexDirection: "row", alignItems: "center", paddingTop: 5,
    },
    ticketTitleText: {
        fontSize: 15, color: colors.title, fontWeight: "600",
    },
    ticketSubTitleText: {
        fontSize: 16, color: colors.white, fontWeight: "600", paddingTop: 5,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            fontSize: 14,
        },
        '@media (max-width: 479px)': {
            fontSize: 14,
        },
    },
    priceSummeryView: {
        backgroundColor: colors.ticketTotalBackground,
        // borderColor: colors.loginViewBorder,
        // borderWidth: 1,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        borderTopWidth: 2,
        borderTopColor: "#030303",
        marginBottom: "2%",
        paddingHorizontal: "5%",
        paddingVertical: "5%",
        justifyContent: "center",
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {
            paddingTop: "10%",
            paddingBottom: "10%",
        },
        '@media (max-width: 479px)': {
            paddingTop: "10%",
            paddingBottom: "10%",
        },
    },
    paymentConfirmView: {
        backgroundColor: colors.ticketTotalBackground,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        borderTopWidth: 2,
        borderTopColor: "#030303",
        paddingHorizontal: "5%",
        paddingVertical: "5%",
        justifyContent: "center",
        borderTopLeftRadius: 30,
        borderTopRightRadius: 30,
    },
    priceSummerySubView: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between"
    },
    priceSummerySubViewWithMarginTop: {
        flexDirection: "row", alignItems: "center", justifyContent: "space-between",
        //  marginTop: 20
    },
    priceSummeryWhiteText: {
        fontSize: 20, color: colors.white, fontWeight: "bold"
    },
    priceSummeryTitleColorText: {
        fontSize: 20, color: colors.white, fontWeight: "bold"
    },
    priceSummeryDivider: {
        alignSelf: "center", width: "100%", height: 2, resizeMode: "stretch", marginTop: 40
    },
    priceSummeryButtonView: {
        flexDirection: "row", alignItems: "center", alignSelf: "center"
    },
    priceSummeryBackButton: {
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#252525",
        marginTop: 22,
        paddingHorizontal: 50,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            height: 40,
        },
        '@media (max-width: 639px)': {
            height: 40,
        },
        '@media (max-width: 479px)': {
            height: 40,
        },
    },
    priceSummeryButtonText: {
        color: colors.white,
        fontSize: 20,
        fontWeight: "600",
        textAlign: 'center',
    },
    priceSummeryNextButton: {
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        paddingHorizontal: 50,
        marginLeft: 20,
        '@media (max-width: 1399px)': {
        },
        '@media (max-width: 1199px)': {
        },
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
            height: 40,
        },
        '@media (max-width: 639px)': {
            height: 40,
        },
        '@media (max-width: 479px)': {
            height: 40,
        },
    },
    ticketSummeryIconBackround: {
        height: 50, width: 50, alignItems: "center", justifyContent: "center",
    },
    ticketSummeryIcons: {
        height: 20, width: 20, resizeMode: "contain",
    },
    ticketSummeryIconsTextView: {
        marginLeft: 10, flex: 1
    },

    // ticket selection
    ticketSelectionpriceSummeryView: {
        backgroundColor: colors.black, padding: 10, borderRadius: 10, paddingVertical: 20, marginTop: 20
    },
    ticketSelectionView: {
        backgroundColor: colors.loginBackground,
        borderColor: colors.loginViewBorder,
        borderWidth: 1,
        marginVertical: "5%",
        // marginHorizontal: wp("2.5%"),
        borderRadius: 30,
        paddingHorizontal: "5%",
        paddingVertical: "5%",
        justifyContent: "center"
    },
    ticketSelectionTypesView: {
        flexDirection: "row", alignItems: 'center',
        '@media (max-width: 991px)': {
            flexDirection: "column",
        },
        '@media (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column",

        },
    },
    ticketTypeView: {
        flexDirection: "row",
        alignItems: "center",
        flex: 1,
        justifyContent: "space-between",
        backgroundColor: colors.black,
        padding: 15,
        borderRadius: 10,
        margin: 5,
        '@media (max-width: 991px)': {
            width: '100%',
            marginBottom: '10px'
        },
        '@media (max-width: 767px)': {
            width: '100%',
            marginBottom: '10px'
        },
        '@media (max-width: 639px)': {
            width: '100%',
            marginBottom: '10px'
        },
        '@media (max-width: 479px)': {
            width: '100%',
            marginBottom: '10px'
        },
    },
    ticketTypeViewWithMarginLeft: {
        flexDirection: "row", alignItems: "center", flex: 1, justifyContent: "space-between", backgroundColor: colors.black, padding: 15, borderRadius: 10, marginLeft: 25
    },
    ticketTypeText: {
        fontSize: 16, color: colors.white, fontWeight: "bold",
        '@media only screen and (min-width:1200px) and (max-width:1399px)': {

        },
        '@media only screen and (min-width:992px) and (max-width:1199px)': {

        },
        '@media only screen and (min-width: 768px) and (max-width: 991px)': {

        },
        '@media only screen and (min-width: 640px) and (max-width: 767px)': {

        },
        '@media only screen and (min-width: 480px) and (max-width: 639px)': {

        },
        '@media only screen and (min-width: 320px) and (max-width: 479px)': {

        },
    },
    ticketTypePrice: {
        fontSize: 15, color: colors.white,
    },
    ticketQtyView: {
        flexDirection: "row", alignItems: "center"
    },
    ticketPlusMinusView: {
        backgroundColor: "#252525", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 5
    },
    ticketQtyText: {
        paddingHorizontal: 15, paddingVertical: 10, fontSize: 20, color: colors.white, fontWeight: "bold"
    },
    ticketAddButton: {
        borderRadius: 10,
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        paddingHorizontal: 20
    },
    ticketAddButtonText: {
        color: colors.white,
        fontSize: 20,
        fontWeight: "600",
        textAlign: 'center',
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {
            fontSize: 16,
        },
        '@media (max-width: 639px)': {
            fontSize: 16,
        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },

    // event details

    guestCheckoutModalMainView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: colors.black
    },
    guestCheckoutModalSubView: {
        // alignItems: 'center',
        justifyContent: "center",
        height: hp("100%"),
        margin: 'auto',
        padding: 30,
        width: wp("100%"),
        paddingHorizontal: wp("7%"),
        paddingVertical: hp("6%")
    },
    guestCheckoutModalCloseButtonView: {
        paddingVertical: 10, paddingHorizontal: 15,
    },
    guestCheckoutModalCloseButtonImage: {
        width: wp("2%"), height: wp("2%"), resizeMode: "contain", tintColor: colors.white
    },
    guestTitle: {
        fontSize: 25,
        color: colors.white,
        textTransform: 'uppercase',
        fontWeight: "bold",
        textAlign: 'center'
    },
    guestCheckoutMainView: {
        backgroundColor: colors.loginBackground,
        borderColor: colors.loginViewBorder,
        borderWidth: 1,
        marginVertical: "5%",
        marginHorizontal: "auto",
        borderRadius: 30,
        paddingHorizontal: "20px",
        paddingVertical: "20px",
        justifyContent: "center",
        width: '400px',
        '@media (max-width: 1399px)': {

        },
        '@media (max-width: 1199px)': {

        },
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            width: '300px'
        },
    },
    buynow_checkoutButtonview: {
        flexDirection: "row", alignItems: "center",
        '@media (max-width: 991px)': {
        },
        '@media (max-width: 767px)': {
        },
        '@media (max-width: 639px)': {
            // flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column",
        },
    },

    eventDetailsMainView: {
        marginHorizontal: "15%",
        '@media (max-width: 991px)': {
            marginRight: 15,
            marginLeft: 15,
        },
        '@media (max-width: 767px)': {
            marginRight: 15,
            marginLeft: 15,
        },
        '@media (max-width: 639px)': {
            marginRight: 15,
            marginLeft: 15,
        },
        '@media (max-width: 479px)': {
            marginRight: 15,
            marginLeft: 15,
        },
    },
    eventDetailsBackView: {
        marginTop: 15, marginBottom: 15, flexDirection: "row", alignItems: "center"
    },
    eventDetailsBackIcon: {
        width: 30, height: 30, tintColor: colors.title, resizeMode: "contain", marginRight: 10
    },
    eventDetailsBackText: {
        fontSize: 34, color: colors.title, fontFamily: getPlusJakartaSansSemiBoldFont(), fontWeight: 'normal',
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {
        },
        '@media (max-width: 639px)': {
        },
        '@media (max-width: 479px)': {
            fontSize: 24,
        },
    },
    eventDetailsBannerView: {
        marginTop: 20, marginBottom: 50
    },
    eventVideoView: {
        marginTop: 50,
    },
    eventDetailsBannerImage: {
        height: 550, width: Dimensions.get("window").width * 0.61, resizeMode: "stretch", borderRadius: 20,
        '@media (max-width: 1920px)': {
            height: 300,
        },
        '@media (max-width: 991px)': {
            height: 300,
        },
        '@media (max-width: 767px)': {
            height: 300,
        },
        '@media (max-width: 639px)': {
            height: 300,
        },
        '@media (max-width: 479px)': {
            height: 300,
        },
    },
    eventTitle: {
        fontSize: 30, color: colors.white, fontFamily: getPlusJakartaSansBoldFont(),
        '@media (max-width: 991px)': {
            fontSize: 40,
        },
        '@media (max-width: 767px)': {
            fontSize: 30,
        },
        '@media (max-width: 639px)': {
            fontSize: 30,
        },
        '@media (max-width: 479px)': {
            fontSize: 30,
            // height: 300,
        },
    },
    eventTimePlaceMoneyView: {
        marginTop: 50
    },
    eventFlexRowView: {
        flexDirection: "row", alignItems: "center"
    },
    eventTimePlaceMoneyBackground: {
        height: 90, width: 90, alignItems: "center", justifyContent: "center",
    },
    eventTimePlaceMoneyIcon: {
        height: 40, width: 40, resizeMode: "contain",
    },
    eventTimePlaceMoneyTextView: {
        marginLeft: 25, flex: 1
    },
    eventTimePlaceMoneyTitleText: {
        fontSize: 25, color: colors.white, fontFamily: getPlusJakartaSansBoldFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            fontSize: 20,
        },
    },
    eventTimePlaceMoneySubTitleText: {
        fontSize: 20, color: colors.title, fontFamily: getPlusJakartaSansRegularFont(),
        '@media (max-width: 991px)': {

        },
        '@media (max-width: 767px)': {

        },
        '@media (max-width: 639px)': {

        },
        '@media (max-width: 479px)': {
            fontSize: 16,
        },
    },
    eventFlexRowViewWithMarginTop: {
        flexDirection: "row", alignItems: "center", marginTop: 25
    },
    eventDetailsMarginVertical: {
        marginVertical: 50
    },
    eventNoForBuy: {
        marginTop: 30
    },
    eventNoForBuytext: {
        fontSize: 30, color: colors.buttonColor, fontFamily: getPlusJakartaSansBoldFont(),
        '@media (max-width: 991px)': {
            fontSize: 30,
        },
        '@media (max-width: 767px)': {
            fontSize: 30,
        },
        '@media (max-width: 639px)': {
            fontSize: 30,
        },
        '@media (max-width: 479px)': {
            fontSize: 30,
        },
    },
    eventDetailsDescriptionTitle: {
        fontSize: 30, color: colors.white, fontFamily: getPlusJakartaSansBoldFont(),
        '@media (max-width: 991px)': {
            fontSize: 30,
        },
        '@media (max-width: 767px)': {
            fontSize: 30,
        },
        '@media (max-width: 639px)': {
            fontSize: 30,
        },
        '@media (max-width: 479px)': {
            fontSize: 30,
        },
    },
    eventDetailsDescriptionSubTitle: {
        fontSize: 30, color: colors.title, marginTop: 40, fontFamily: getPlusJakartaSansRegularFont(),
        '@media (max-width: 991px)': {
            fontSize: 20,
        },
        '@media (max-width: 767px)': {
            fontSize: 20,
        },
        '@media (max-width: 639px)': {
            fontSize: 20,
        },
        '@media (max-width: 479px)': {
            fontSize: 20,
        },
    },
    eventDetailsSeparator: {
        backgroundColor: colors.white, height: 2
    },
    eventFaqView: {
        marginTop: 40,
    },
    eventFaqSubView: {
        borderBottomWidth: 1, borderBottomColor: colors.borderColor,
    },
    eventFaqHeadingView: {
        paddingVertical: 20, flexDirection: "row", alignItems: "center", justifyContent: "space-between"
    },
    eventFaqHeadingText: {
        fontSize: 30, color: colors.white, textAlign: "left", fontFamily: getPlusJakartaSansBoldFont()
    },
    eventFaqIcon: {
        marginHorizontal: 9,
        left: 5,
        height: 20,
        width: 20,
        resizeMode: "contain",
        tintColor: colors.white
    },
    eventFaqDetailView: {
        paddingBottom: 50, paddingTop: 20
    },
    eventFaqDetailText: {
        fontSize: 25, color: colors.title, textAlign: "left", fontFamily: getPlusJakartaSansRegularFont()
    },
    eventQrCodeMainView: {
        marginVertical: 50, backgroundColor: "#212121", paddingVertical: 40, borderRadius: 20, paddingHorizontal: 60
    },
    eventQrScanMeText: {
        fontSize: 40, color: colors.white, fontWeight: "bold", textAlign: "center", fontFamily: getPlusJakartaSansBoldFont()
    },
    eventQrImage: {
        resizeMode: "contain", marginTop: 30, marginHorizontal: 15, height: hp("50%"), width: hp("50%"), alignSelf: "center"
    },
    eventTicketRange: {
        fontSize: 30, color: colors.title, marginVertical: 40, textAlign: 'center', fontFamily: getPlusJakartaSansRegularFont()
    },
    eventFavoriteButtonView: {
        flexDirection: "row",
        alignItems: "center",
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#252525",
        marginTop: 22,
        borderWidth: 1,
        borderColor: colors.title
    },
    eventFavoriteButtonText: {
        color: colors.title,
        fontSize: 20,
        fontWeight: "600",
        textAlign: 'center',
        fontFamily: getPlusJakartaSansBoldFont()
    },
    eventFavoriteIconActive: {
        width: 30,
        height: 30,
        marginRight: 10,
        resizeMode: "contain",
        tintColor: colors.buttonColor,
    },
    eventFavoriteIconInActive: {
        width: 30,
        height: 30,
        marginRight: 10,
        resizeMode: "contain",
        tintColor: colors.title
    },

    // common screen
    commonRenderHtmlText: {
        color: colors.white, marginTop: 50, marginHorizontal: "5%", fontSize: hp("2%"), fontFamily: getPlusJakartaSansRegularFont()
    },

    // contact us screen
    contactUsInformationMainView: {
        flexDirection: "row",
        '@media (max-width: 991px)': {
            flexDirection: "column",
        },
        '@media (max-width: 767px)': {
            flexDirection: "column",
        },
        '@media (max-width: 639px)': {
            flexDirection: "column",
        },
        '@media (max-width: 479px)': {
            flexDirection: "column",
        },

    },
    contactUsInformationView: {
        marginHorizontal: "2.5%", marginTop: "10%"
    },
    contactUsInformationText: {
        textAlign: "left", fontSize: 25,
        marginBottom: 15,
        color: colors.white,
        fontFamily: getPlusJakartaSansBoldFont()
    },
    contactUsInformationSubView: {
        marginTop: 20
    },
    contactUsInformationFlexRow: {
        flexDirection: "row", alignItems: "center"
    },
    contactUsInformationFlexRowWithMarginTop: {
        flexDirection: "row", alignItems: "center", marginTop: 25
    },
    contactUsInformationBgView: {
        height: 90, width: 90, alignItems: "center", justifyContent: "center",
    },
    contactUsInformationIcon: {
        height: 40, width: 40, resizeMode: "contain",
    },
    contactUsInformationPressable: {
        marginLeft: 25, flex: 1
    },
    contactUsInformationPressableText: {
        flex: 1, fontSize: 20, color: colors.title, fontFamily: getPlusJakartaSansRegularFont()
    },

    // thank you screen
    thankYouMainView: {
        height: 400, justifyContent: "center"
    },
    thankYouText: {
        fontSize: 50, color: colors.white, fontFamily: getPlusJakartaSansBoldFont(), alignSelf: "center",
        '@media (max-width: 991px)': {
            fontSize: 30,
        },
        '@media (max-width: 767px)': {
            fontSize: 30,
        },
        '@media (max-width: 639px)': {
            fontSize: 30,
        },
        '@media (max-width: 479px)': {
            fontSize: 30,
        },
    },
    thankYouSubText: {
        fontSize: 30, color: colors.white, fontFamily: getPlusJakartaSansRegularFont(), alignSelf: "center", marginTop: 20,
        '@media (max-width: 991px)': {
            fontSize: 20,
        },
        '@media (max-width: 767px)': {
            fontSize: 20,
        },
        '@media (max-width: 639px)': {
            fontSize: 20,
        },
        '@media (max-width: 479px)': {
            fontSize: 20,
        },
    },
    viewTicketButtonView: {
        borderRadius: 10,
        height: 60,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colors.buttonColor,
        marginTop: 22,
        paddingHorizontal: 50,
        marginLeft: 20,
        alignSelf: "center"
    },
    viewTicketButtonText: {
        color: colors.white,
        fontSize: 20,
        fontWeight: "600",
        textAlign: 'center',
    },
    customProgressBarView: {
        flex: 1, alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgba(0,0,0,0.5)'
    },
    customProgressBarSubView: {
        alignItems: 'center',
        borderColor: '#eee',
        borderRadius: 10,
        height: 300,
        margin: 'auto',
        padding: 30,
        width: 300
    },
    customProgressBarLoadingText: {
        fontWeight: "400",
        fontSize: 30,
        lineHeight: 18,
        color: colors.white,
        textAlign: "center",
        marginTop: 10,
        marginLeft: 10,
        fontFamily: getPlusJakartaSansMediumFont()
    }




});

export { ids, styles };