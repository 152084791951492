import React, { useState } from 'react';
import { StatusBar, TextInput, View, Text, ScrollView, Pressable, Image } from 'react-native';
// import { styles } from '../common/style';
import { BackgroundView, CustomConsole, CustomProgressBar, FooterComponent, HeaderComponent, scrollToTop, validateEmail, } from '../common/utils';
import { colors } from '../common/color';
import { CHANGEPASSWORD, FORGOTPASSWORDRESET, VERIFYOTP } from '../common/webUtils';
import { useIsFocused } from '@react-navigation/native';
import { ACCESS_TOKEN, CUSTOMER_ID, EMAIL, NAME, PHONE, SOCIAL_LOGIN, USER_ID, getSession, saveSession } from '../common/LocalStorage';
import { ids, styles } from '../common/responsive';

function ChangePasswordScreen({ navigation, route }) {

    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState('');

    const [oldPassword, setOldPassword] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);

    // const emailId = route.params.paramEmail;
    const token = route.params.paramToken;

    //for check screen focused
    const isFocused = useIsFocused();

    //useeffect
    React.useEffect(() => {
        getSessionData();
        scrollToTop();
    }, [isFocused])

    // get sessiondata
    const getSessionData = async () => {
        try {
            const user_id = await getSession(USER_ID);
            setUserId(user_id);
        } catch (error) {

        }
    }

    // changePassword
    const changePassword = () => {


        if (email.trim().length == 0) {
            alert("Enter email");
        }
        else if (!validateEmail(email.trim())) {
            alert("Invalid email");
        }
        else if (newPassword.trim().length == 0) {
            alert("Enter new password");
        }
        else if (newPassword.trim().length < 8) {
            alert("Please enter new password more than 8 characters");
        }
        else if (confirmPassword.trim().length == 0) {
            alert("Enter confirm password");
        }
        else if (newPassword.trim() != confirmPassword.trim()) {
            alert("Please Check Password and Confirm Password. Both should be same");
        }
        else {
            try {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "token": token,
                    "email": email,
                    "password": newPassword,
                    "password_confirmation": confirmPassword
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                CustomConsole(FORGOTPASSWORDRESET + "\n" + raw);

                setLoading(true);
                fetch(FORGOTPASSWORDRESET, requestOptions)
                    .then(response => response.json())
                    .then((json) => {
                        CustomConsole(json);
                        if (json.status == 1) {
                            alert(json.message);
                            setLoading(false);
                            navigation.navigate('LoginScreen');
                            navigation.reset({
                                index: 0,
                                routes: [{ name: 'LoginScreen' }],
                            });
                        } else {
                            alert(json.message);
                            setLoading(false);
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        CustomConsole("Error=>" + error);
                    });

            } catch (e) {
                setLoading(false);
                CustomConsole("Exception=>" + e + "");
            }
        }
    }

    return (
        <View style={styles.container}>
            {BackgroundView()}
            <StatusBar barStyle="dark-content" />

            <ScrollView style={styles.scrollContainer} dataSet={{ media: ids.scrollContainer }} keyboardShouldPersistTaps='handled'>
                <View style={{}}>

                    {/* progress view */}
                    {loading ? CustomProgressBar(loading) : null}
                    {/* end of progress view */}

                    {/* Header view */}
                    {HeaderComponent("3", navigation, userId)}
                    {/* end of Header view */}

                    {/* change password view */}
                    <View style={styles.loginMainView}>

                        <Text style={styles.viewTitle}>Change Password</Text>

                        {/* email */}
                        <Text style={styles.textInputTitle} dataSet={{ media: ids.textInputTitle }}>Email</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={email}
                                onChangeText={txt => { setEmail(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter Email"
                                dataSet={{ media: ids.textInput }}
                            />
                            <Image source={require("../assets/email2.png")} style={styles.textInputIcon} />
                        </View>

                        {/* New password */}
                        <Text style={styles.textInputTitle}>New Password</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={newPassword}
                                onChangeText={txt => { setNewPassword(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter New Password"
                                secureTextEntry={!passwordShow}
                                dataSet={{ media: ids.textInput }}
                            />
                            {/* <Image source={require("../assets/password.png")} style={styles.textInputIcon} /> */}
                            <Pressable onPress={() => { setPasswordShow(!passwordShow) }}>
                                <Image source={passwordShow ? require("../assets/eye_show.png") : require("../assets/eye_hide.png")}
                                    style={styles.textInputIcon} dataSet={{ media: ids.textInputIcon }} />
                            </Pressable>
                        </View>

                        {/* confirm password */}
                        <Text style={styles.textInputTitle}>Confirm Password</Text>
                        <View style={styles.textInputView}>
                            <TextInput
                                style={styles.textInput}
                                value={confirmPassword}
                                onChangeText={txt => { setConfirmPassword(txt) }}
                                placeholderTextColor={colors.placeHolderColor}
                                placeholder="Enter Confirm Password"
                                secureTextEntry={!confirmPasswordShow}
                                dataSet={{ media: ids.textInput }}
                            />
                            {/* <Image source={require("../assets/password.png")} style={styles.textInputIcon} /> */}
                            <Pressable onPress={() => { setConfirmPasswordShow(!confirmPasswordShow) }}>
                                <Image source={confirmPasswordShow ? require("../assets/eye_show.png") : require("../assets/eye_hide.png")}
                                    style={styles.textInputIcon} dataSet={{ media: ids.textInputIcon }} />
                            </Pressable>
                        </View>



                        {/* verify otp button view */}
                        <Pressable style={styles.commonButtonView} onPress={changePassword}>
                            <Text style={styles.commonButtonText}>Change Password</Text>
                        </Pressable>
                        {/* end of verify otp button view */}

                    </View>
                    {/* end of change password view */}

                    {/* footer view */}
                    {FooterComponent(navigation)}
                    {/* end of footer view */}

                </View>
            </ScrollView>

        </View>
    );
};

export default ChangePasswordScreen;
