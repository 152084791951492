//common urls
// export const COMMONURL = "https://hiindiaevents.com/adminhiindia/api/"
// export const COMMONURL = "https://hiindiaevents.com/manage/api/"//live2_test_for_CORS
// export const COMMONURL = "https://hiindiaevents.com/eventadmin/api/"

export const COMMONURL = "https://newhiindia.alakmalak.ca/api/" //dev url
// export const COMMONURL = "https://admin.hiindiaevents.com/api/"//live1 main

// Login
export const LOGIN = COMMONURL + "weblogin"
export const REGISTER = COMMONURL + "register"
export const SENDOTP = COMMONURL + "send-otp"
export const FORGOTPASSWORD = COMMONURL + "forget-password"
export const CHANGEPASSWORD = COMMONURL + "change-password"
export const FORGOTPASSWORDRESET = COMMONURL + "forgot/password/resetsubmit"
export const VERIFYOTP = COMMONURL + "verify-otp"
export const UPDATEPROFILE = COMMONURL + "set-profile"
export const GETPROFILE = COMMONURL + "get-profile"
// export const DELETEUSER = COMMONURL + "/delete_user"
// export const LOGOUT = COMMONURL + "/account.logout&lang=en&currency=INR"

// // dashboard
export const BANNER = COMMONURL + "banner_list"
export const CATEGORY = COMMONURL + "categories"
export const UPCOMINGEVENTS = COMMONURL + "upcoming-events"
export const FAVORITEEVENTS = COMMONURL + "favorite-events-list"
export const ADDFAVORITEEVENT = COMMONURL + "favourite-event"
export const EVENTDETAILS = COMMONURL + "event-details"
export const TICKETLIST = COMMONURL + "booking-list"
export const TICKETDETAILS = COMMONURL + "booking-details"
export const OTHERSCREENS = COMMONURL + "other-screen"
export const CONTACTUS = COMMONURL + "contactus"

export const EVENTBOOKING = COMMONURL + "event-booking"
export const PAYMENTMETINTENT = COMMONURL + "payment-intent"
export const GUESTCHECKOUT = COMMONURL + "guest"
// export const FAQ = COMMONURL + "/faq.list"
// export const STORY = COMMONURL + "/product.getProductStories"
// export const TOPREVIEWS = COMMONURL + "/review.getTopReviews"
// export const CMSPAGE = COMMONURL + "/information&id="

// // product
// export const PRODUCT = COMMONURL + "/product&category=&currency=INR&lang="
// export const PRODUCTDETAILS = COMMONURL + "/product_detail"
// export const RELATEDPRODUCTS = COMMONURL + "/product.getRelatedProducts"
// export const PRODUCTREVIEWS = COMMONURL + "/review"
// export const ADDTOCART = COMMONURL + "/cart.add"
// export const SUBSCRIBELIST = COMMONURL + "/subscription"
// export const CARTLIST = COMMONURL + "/cart.list"
// export const ADDREVIEW = COMMONURL + "/review"
// export const EMPTYCART = COMMONURL + "/cart.emptyCart"
// export const COUPANS = COMMONURL + "/cart.coupons"
// export const APPLYCOUPANS = COMMONURL + "/cart.coupon&lang=en&currency=INR"
// export const PAYMENTMETHODS = COMMONURL + "/payment_method"

// // orders
// export const ORDERLIST = COMMONURL + "/order.orders"
// export const SUBSCRIPTIONLIST = COMMONURL + "/order.subscriptionOrders"
// export const CONFIRMORDER = COMMONURL + "/order.confirm&currency=INR&lang=gr"

export const COUNTRY = COMMONURL + "countries"
// export const STATE = COMMONURL + "/shipping_address.states"
export const CITY = COMMONURL + "cities"


